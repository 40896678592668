import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useUploadFiles from "./hooks/useUploadFiles";
import useDropFiles from "./hooks/useDropFiles";
import { useUploadNotesState } from "../../hook/useUploadNotes";
import uploadNotesIcon from "@assets/icons/uploadNotes.png";
import { useSelector, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import { FilePlus } from "lucide-react";
import Heading from "@ui/heading/heading";
import Field from "@ui/field/field";
import Input from "@ui/input/input";
import TextArea from "@ui/textarea/textarea";
import Button from "@ui/button/button";
import ChatModalFreeSubscription from "../../../chat/chatCard/chatModalFreeSubscription/chatModalFreeSubscription";
import { setSubmitDocState } from "../../../../redux/actions/documentActions";
import useGetDocuments from "../../documentsQA/hooks/useGetDocuments";
import "./uploadNotesForm.scss";
import { toast } from "react-toastify";

const UploadNotesForm = ({ addMoreFiles }) => {
  const { fetcher, register, disabledForm, onNotesChange, uploadNotes, showTextArea, saveText, closeTextArea } = useUploadFiles();
  const { isDragging, onDragOver, onDragLeave, dropFiles } = useDropFiles(uploadNotes);
  const { processedNotes } = useUploadNotesState();
  const { user } = useSelector(state => state.user);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const isFreeAccount = user?.role !== "Admin" && user?.plan === "Base";
  const { isLoading, isGenerate } = useSelector(state => state.document);
  const { getDocuments } = useGetDocuments()
  const dispatch = useDispatch();
  const isSubmitting = fetcher.state === "submitting";
  const currProcessedNotes = processedNotes;

  // Salvataggio state per assicurarsi di renderizzare il Loader al momento giusto
  useEffect(() => {
    if(isSubmitting) {
      dispatch(setSubmitDocState(isSubmitting));
    } else {
      dispatch(setSubmitDocState(false));
    }
  }, [isSubmitting, dispatch, processedNotes]);
  
  useEffect(() => {
    console.log(currProcessedNotes)
    if(currProcessedNotes.length !== processedNotes) {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
   
    //End upload if it's a pages file
    if(showTextArea && processedNotes.length === 0) {
      dispatch({ type: "SET_IS_LOADING", payload: false });  
    }
  }, [processedNotes, currProcessedNotes, dispatch, showTextArea]);

  const handleAddFile = (event) => {
    // Verify if file's size is greather then 24mb
    const fileDimension = parseInt(event.target.files[0].size / 1024 / 1024);
    const fileType = event.target.files[0].type;
    
    if(fileDimension > 24 && (fileType.startsWith('audio/') || fileType.startsWith('video/'))) {
      toast.info('Inserisci un file di dimensione minore di 25mb');
      return;
    };
    
    dispatch({ type: "SET_IS_LOADING", payload: true });

    if ((processedNotes.length >= 2 || filesUploaded === '2 / 2') && user?.plan !== 'Premium') {
      setShowModal(true);
      dispatch({ type: "SET_IS_PROCESSING", payload: false });
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return;
    }
  
    dispatch({ type: "SET_GENERATE", payload: true });
    onNotesChange(event);
  };

  const handleGenerateExam = () => {
    dispatch({ type: "SET_PROCESSING_STATE", payload: true });
    dispatch({ type: "SET_GENERATE", payload: false });
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch(setSubmitDocState(isSubmitting));
    getDocuments();
  };  
  
  useEffect(() => {
    if(user?.plan !== 'Premium' && (user?.documentsUploaded === 2 || (user?.documentsUploaded === 1 && processedNotes.length >= 1))) {
      setFilesUploaded('2 / 2');
    }  else if(user?.plan !== 'Premium' && user?.documentsUploaded === 1) {
      setFilesUploaded('1 / 2');
    } else {
      setFilesUploaded(`${processedNotes.length} / 2`);
    } 
  }, [user.documentsUploaded, processedNotes.length, user.plan]);
  
  return (
    <>
      {(showModal && isFreeAccount) && <ChatModalFreeSubscription onClose={() => setShowModal(false)} uploadingDoc={true} />}
      <fetcher.Form
        className={clsx(
          `UploadNotesForm ${addMoreFiles && 'second-form'}`,
          isDragging && "UploadNotesForm--active",
          (((isLoading || disabledForm || showTextArea) ) || (!addMoreFiles && processedNotes.length > 0)) && "UploadNotesForm--disabled",
        )}
        onDrop={dropFiles}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        action="/home?index"
        method="post"
        encType="multipart/form-data"
      >
        <div className="UploadNotesForm__inner">
          {
            addMoreFiles ? 
              <>
                {!isDragging && (
                  <Field className={`UploadNotesForm__field ${addMoreFiles && 'UploadNotesForm__field-second-form'}`}>
                    {<Field.Label className="UploadNotesForm__field-label" label="Aggiungi File" htmlFor="notes" />}
                    <Input
                      className={`UploadNotesForm__field-input ${addMoreFiles && 'UploadNotesForm__input-second-form'}`}
                      id="notes"
                      name="notes"
                      type="file"
                      accept=".pdf, .txt, .doc, .docx, .jpg, .png, .jpeg, .ppt, .pptx, .pages, .key, .mp3, .mp4"
                      multiple
                      {...register("files")}
                      onChange={(event) => handleAddFile(event)}
                    />
                  </Field>
                )}
                <FilePlus size={45} className="second-form__more-files-icon" />
              </>
              
            : 
              <>
                <img className="UploadNotesForm__icon" src={uploadNotesIcon} alt="upload notes" />
                <Heading className="UploadNotesForm__title" title="Trascina o Seleziona i file da caricare" heading="h6" />
                <p className="UploadNotesForm__text">Importa dei documenti per estrarre le domande</p>
                
                {!isDragging && processedNotes.length === 0 ?  (
                  <Field className="UploadNotesForm__field">
                    <Field.Label className="UploadNotesForm__field-label" label="Cerca File" htmlFor="notes" />
                    <Input
                      className="UploadNotesForm__field-input"
                      id="notes"
                      name="notes"
                      type="file"
                      accept=".pdf, .txt, .doc, .docx, .jpg, .png, .jpeg, .ppt, .pptx, .pages, .key, .mp3, .mp4"
                      multiple
                      {...register("files")}
                      onChange={(event) => handleAddFile(event)}
                    />
                  </Field>)
                  
                  : null
                }
              </>
          }
        </div>
      </fetcher.Form>
      <>
      
      {(user?.plan !== 'Premium' && !addMoreFiles && processedNotes.length === 0) && (
        <div className="UploadNotesForm__info-container-first-form">
          <p className="UploadNotesForm__info">
            <strong>Documenti caricati:</strong> {filesUploaded}
          </p>
        </div>
      )}
        
      {user?.plan !== 'Premium' && addMoreFiles
        ?
          <div className="UploadNotesForm__form-footer">
            {user?.documentsUploaded === undefined && (
              <p className="UploadNotesForm__info">
                <strong>Documenti caricati:</strong> {filesUploaded}
              </p>
            )}
            {typeof user.documentsUploaded === "number" && (
              <p className="UploadNotesForm__info">
                <strong>Documenti caricati:</strong> {filesUploaded}
              </p>
            )}

            {
              (processedNotes.length && !isSubmitting && (!isLoading)) && isGenerate
               
              ? 
                <button className={`UploadNotesForm__form-footer-btn ${(isSubmitting || !processedNotes.length) && 'btn-disabled'}`} onClick={handleGenerateExam}>
                  Genera Esame
                </button>
                
              : null
            }
          </div> 
        
        : 
          (processedNotes.length && !isSubmitting && (!isLoading) && addMoreFiles) && isGenerate
            
          ? <div className="UploadNotesForm__generate-container">
              <button className={`UploadNotesForm__form-footer-btn ${(isSubmitting || !processedNotes.length) && 'btn-disabled'}`} onClick={handleGenerateExam}>
                Genera Esame
              </button>
            </div>
            
          : null 
      }
      </>
      {showTextArea && (
        <div className="UploadNotesForm__textarea-container">
          <p className="UploadNotesForm__error">
            <span className="UploadNotesForm__error-title">Problema nel caricamento</span><br /><br />
            Non possiamo leggere testo dai file Pages o Keynote,
            per favore copia e incolla il tuo testo qui
            o converti il file in PDF e caricalo sopra.
          </p>
          <button onClick={closeTextArea} className="UploadNotesForm__close-button">
            <IoClose className="UploadNotesForm__close-icon" />
          </button>
          <TextArea
            id="manualText"
            className="UploadNotesForm__textarea"
            placeholder="Inserisci il testo qui..."
            {...register("manualText")}
          />
          <Button onClick={saveText} className="UploadNotesForm__save-button">Salva</Button>
        </div>
      )}
    </>
  );
};

export default UploadNotesForm;
