import { useDispatch } from 'react-redux';
import { useUploadNotesAction } from '../../../../hook/useUploadNotes'

const useDeleteNoteFile = name => {
  const dispatch = useUploadNotesAction();
  const dispatchDoc = useDispatch()
  
  // delete processed note file
  const deleteNoteFile = () => {
    dispatch({ type: 'DELETE__NOTE_FILE', payload: name })
    dispatchDoc({ type: "SET_GENERATE", payload: true });
  }

  return { deleteNoteFile }
}

export default useDeleteNoteFile
