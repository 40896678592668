import React from "react";
import "./documentsQAInfo.scss";
import Heading from "@ui/heading/heading";

import PdfIcon from "@assets/icons/pdf.png";
import DocxIcon from "@assets/icons/docx.png";
import PptxIcon from "@assets/icons/pptx.png";
import SchemiIcon from "@assets/icons/Schemi.png";
import MappeconcettualiIcon from "@assets/icons/Mappe concettuali.png";
import RiassuntiIcon from "@assets/icons/Riassunti.png";
import FotoOImmaginiIcon from "@assets/icons/Foto o Immagini.png";
import AppuntiIcon from "@assets/icons/Appunti.png";


import AudiolezioniIcon from "@assets/icons/Audiolezioni.png";
import VideolezioniIcon from "@assets/icons/Videolezioni.png";

const DocumentsQAInfo = () => {
  return (
    <div data-testid="documents-qa-info" className="DocumentsQAInfo">
      <Heading heading="h5" title="Ripeti qualsiasi materiale di studio a partire da:" className="custom-h5" />
      <div className="DocumentsQAInfo__list">
        <div><img src={AudiolezioniIcon} alt="Audio lezioni" /> Audio lezioni</div>
        <div><img src={VideolezioniIcon} alt="Video lezioni" /> Video lezioni</div>
        <div><img src={FotoOImmaginiIcon} alt="Foto o Immagini" /> Immagini</div>
        <div><img src={MappeconcettualiIcon} alt="Mappe concettuali" /> Mappe concettuali</div>
        <div><img src={PdfIcon} alt="PDFs" /> PDFs</div>
        <div><img src={RiassuntiIcon} alt="Riassunti" /> Riassunti</div>
        <div><img src={DocxIcon} alt="Docs" /> Docs</div>
        <div><img src={SchemiIcon} alt="Schemi" /> Schemi</div>
        <div><img src={PptxIcon} alt="PowerPoint" /> PowerPoint</div>
        <div><img src={AppuntiIcon} alt="Appunti" /> Appunti</div>
      </div>
    </div>
  );
};

export default DocumentsQAInfo;
