import React, { useState } from "react";
import { HelpCircle } from "lucide-react";
import "./examSummary.scss";

const ExamSummary = ({ score, totalQuestions }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const getScoreColor = () => {
    if (score >= 7) return '#22c55e';
    if (score >= 5) return '#eab308';
    return '#ef4444';
  };

  const getPersonalizedMessage = (score, totalQuestions) => {
    const percentage = (score / totalQuestions) * 100;
    if (percentage >= 90) return "Eccellente! Continua così!";
    if (percentage >= 70) return "Buon lavoro! C'è ancora spazio per migliorare.";
    if (percentage >= 50) return "Stai facendo progressi. Concentrati sulle aree di miglioramento.";
    return "Non mollare! Rivedi il materiale e prova di nuovo.";
  };

  return (
    <div className="ExamSummary">
      <div className="ExamSummary__score">
        <div className="ExamSummary__score-value" style={{ color: getScoreColor(score) }} >
          <span>{score}/10</span>
          <HelpCircle onClick={togglePopup} size={28} className="info-icon" />
        </div>
        <div
          className={`ExamSummary__feedback ExamSummary__feedback--${getScoreColor(score)}`} style={{ backgroundColor:getScoreColor(score) }}
        >
          {getPersonalizedMessage(score, totalQuestions)}
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <p className="ExamSummary__popup-title">Come viene calcolato il punteggio?</p>
            <div className="ExamSummary__popup-content">
              <p>l punteggio dell'esame viene calcolato in base alla media di ogni singola risposta. Ogni risposta viene valutata in maniera autonoma considerando la pertinenza, la completezza, la correttezza e la struttura dell'esposizione, utilizzando la griglia di valutazione ufficiale come riferimento. Alla fine dell'esame, il punteggio finale viene determinato dalla media aritmetica di tutte le risposte fornite durante la simulazione, per restituire un voto realistico e rappresentativo della performance complessiva dello studente..</p>
            </div>
            <button className="close-btn" onClick={togglePopup}>Chiudi</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamSummary;