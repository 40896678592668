import React, { useState, useEffect, useRef } from "react";
import { ChevronDown } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from "clsx";

import "./Dropmenudown.scss";

const DropMenudown = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Funzione di scroll con offset
  const scrollToSection = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const headerOffset = 100; // Aggiungi un offset per compensare l'header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleFAQClick = () => {
    setIsOpen(false);
    if (location.pathname !== '/') {
      navigate('/#faq');
      setTimeout(() => {
        scrollToSection('faq');
        if (onClose) onClose();
      }, 100);
      return;
    }
    scrollToSection('faq');
    if (onClose) onClose();
  };

  const handleRecensioniClick = () => {
    setIsOpen(false);
    if (location.pathname !== '/') {
      navigate('/#beta-testimonial-slider');
      setTimeout(() => {
        scrollToSection('beta-testimonial-slider');
        if (onClose) onClose();
      }, 100);
      return;
    }
    scrollToSection('beta-testimonial-slider');
    if (onClose) onClose();
  };

  const handleChiSiamoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll verso l'alto
    setTimeout(() => {
      navigate('/chi-siamo'); // Naviga alla pagina "Chi Siamo" dopo lo scroll
      if (onClose) onClose();
    }, 100); // Ritardo per completare lo scroll prima di navigare
  };

  const menuItems = [
    {
      label: 'Recensioni',
      onClick: handleRecensioniClick
    },
    {
      label: 'FAQ',
      onClick: handleFAQClick
    },
    {
      label: 'Chi Siamo',
      onClick: handleChiSiamoClick
    }
  ];

  return (
    <div 
      ref={dropdownRef}
      className="DropMenudown"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <button
        className={clsx(
          "DropMenudown__trigger",
          isOpen && "DropMenudown__trigger--active"
        )}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        Altro
        <ChevronDown 
          size={18}
          className={clsx(
            "DropMenudown__chevron",
            isOpen && "DropMenudown__chevron--rotated"
          )}
        />
      </button>

      <div 
        className={clsx(
          "DropMenudown__content",
          isOpen && "DropMenudown__content--visible"
        )}
      >
        <div className="DropMenudown__items">
          {menuItems.map((item, idx) => (
            <button
              key={idx}
              onClick={() => {
                item.onClick();
                setIsOpen(false);
              }}
              className="DropMenudown__item"
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropMenudown;
