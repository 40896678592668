import FeedbackAnswers from "../feebackAnswers/feebackAnswers";
import "./Question.scss";
import { 
  ChevronDown, 
  ChevronUp, 
  CheckCircle, 
  XCircle, 
  MinusCircle,
  MessageCircle,
  CircleArrowRight,
} from "lucide-react";

const Question = ({ index, status, colors, userAnswer, question, feedback, openQuestion, setOpenQuestion }) => {  

  const getQuestionIcon = (status) => {
    switch (status) {
      case 'correct':
        return <CheckCircle style={{ color: '#16A34A' }} className="Question__header-icon" size={16} />;
      case 'incorrect':
        return <XCircle style={{ color: '#F44336' }} className="Question__header-icon" size={16} />;
      case 'partial':
        return <MinusCircle style={{ color: '#f59e0b' }} className="Question__header-icon" size={16} />;
      case 'skipped':
        return <CircleArrowRight className="Question__header-icon" size={16} />;
      default:
        return null;
    }
  };

  const getQuestionClass = (status) => {
    switch (status) {
      case 'correct':
        return 'Question__container Question__container--correct';
      case 'incorrect':
        return 'Question__container Question__container--incorrect';
      case 'partial':
        return 'Question__container Question__container--partial';
      case 'skipped':
        return 'Question__container Question__container--skipped';
      default:
        return 'Question__container';
    }
  };

  const toggleQuestion = (id) => {
    setOpenQuestion(openQuestion === id ? null : id);
  };

  return (
    <div key={index} className={getQuestionClass(status)}  onClick={() => toggleQuestion(index)} >
      <div className="Question__header">
        <h4 className="Question__title" style={{ color: colors.text }}>
          <div className="Question__title-wrapper">
            <span>
              {getQuestionIcon(status)}
            </span>
            <span className={`Question__title-badge Question__title-badge--${status}`}>
              Domanda {index + 1}
            </span>
          </div>
        </h4>
        {openQuestion === index ? (
          <ChevronUp className="Question__icon" />
        ) : (
          <ChevronDown className="Question__icon" />
        )}
      </div>
      
      <h4 className="Question__title-text">
        {question}
      </h4>

      {openQuestion === index && (
        <div className="Question__content">
          <div className="Question__answer">
            <div className="Question__answer-title">
              <MessageCircle size={15}/>
              <h5 className="Question__answer-title-text" style={{ color: colors.text }}>Risposta:</h5>
            </div>
            <p className="Question__answer-text">{userAnswer}</p>
          </div>
          <div className={`Question__feedback Question__feedback--${status}`}>
            <div className="Question__feedback-title">
              <MessageCircle size={15} className="Question__feedback-icon" />
              <h5 className="Question__feedback-title-text" style={{ color: colors.text }}>Feedback:</h5>
            </div>
            <FeedbackAnswers feedback={feedback} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Question;
