import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Logo from "@ui/logo/logo";
import { IoMdClose } from "react-icons/io";
import Navbar from "@ui/navbar/navbar";
import DropMenudown from "../../pages/landing/header/DropMenudown/Dropmenudown";

/* styles */
import "./landingMenu.scss";

const LandingMenu = ({ onClose }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    onClose();
  };

  useEffect(() => {
    // Nascondi l'header principale
    const mainHeader = document.querySelector('.LandingHeader__content');
    if (mainHeader) {
      mainHeader.style.display = 'none';
    }

    // Nascondi il CoPilot
    const copilot = document.querySelector('co-pilot');
    if (copilot) {
      copilot.style.display = 'none';
    }

    return () => {
      // Mostra l'header principale
      if (mainHeader) {
        mainHeader.style.display = '';
      }

      // Mostra il CoPilot
      if (copilot) {
        copilot.style.display = 'block';
      }
    };
  }, []);

  return (
    <div className="LandingMenu">
      <header className="LandingMenu__header">
        <Logo
          className="LandingMenu__header-logo"
          onClick={() => handleNavigate('/')}
        />
        <div className="LandingMenu__header-box" onClick={onClose}>
          <IoMdClose />
        </div>
      </header>
      <Navbar className="LandingMenu__navbar">
        <Navbar.HashItem
          className="LandingMenu__navbar-navItem"
          to="/#functionality"
          onClick={() => handleNavigate('/#functionality')}
        >
          Come Funziona?
        </Navbar.HashItem>
        <Navbar.HashItem
          className="LandingMenu__navbar-navItem"
          to="/#video-section"
          onClick={() => handleNavigate('/#video-section')}
        >
          Video
        </Navbar.HashItem>
        <Navbar.HashItem
          className="LandingMenu__navbar-navItem"
          to="/#price"
          onClick={() => handleNavigate('/#price')}
        >
          Piani e Prezzi
        </Navbar.HashItem>
        <Navbar.HashItem
          className="LandingMenu__navbar-navItem"
          to="/#studierai-stats"
          onClick={() => handleNavigate('/#studierai-stats')}
        >
          Benefici
        </Navbar.HashItem>

        {/* Passa onClose a DropMenudown */}
        <DropMenudown onClose={onClose} />

        <Navbar.Item
          className="LandingMenu__navbar-navItem LandingMenu__navItem--auth"
          to="/signIn"
          title="Accedi"
          onClick={() => handleNavigate('/signIn')}
        >
          Accedi
        </Navbar.Item>
        <Navbar.Item
          className="LandingMenu__navbar-navItem LandingMenu__navItem--auth"
          to="/signUp"
          title="Registrati Gratis"
          onClick={() => handleNavigate('/signUp')}
        >
          Registrati Gratis
        </Navbar.Item>
      </Navbar>
    </div>
  );
};

LandingMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LandingMenu;
