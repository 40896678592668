import { useState, useCallback, useEffect, useRef } from 'react';

const LOG_THROTTLE_MS = 500;

export const useFeedback = (extractedQA = [], onFeedbackChange) => {
  const mountedRef = useRef(false);
  const lastLogTime = useRef(Date.now());
  const feedbackMapRef = useRef(new Map());
  const previousLengthRef = useRef(0);

  // Initialize feedback states
  const [feedbackStates, setFeedbackStates] = useState(() => {
    console.log(`📝 Inizializzazione feedback per ${extractedQA.length} domande`);
    return Array(extractedQA.length).fill().map(() => ({
      isHelpful: null,
      timestamp: null,
      userId: null
    }));
  });

  // Log statistics with throttling
  const logStats = useCallback((states) => {
    const now = Date.now();
    if (now - lastLogTime.current < LOG_THROTTLE_MS) return;

    const stats = states.reduce((acc, f) => {
      if (f?.isHelpful === true) acc.positive++;
      else if (f?.isHelpful === false) acc.negative++;
      else acc.pending++;
      return acc;
    }, { positive: 0, negative: 0, pending: 0 });

    console.log(
      `📊 Feedback: ✅ ${stats.positive} positivi | ❌ ${stats.negative} negativi | ⏳ ${stats.pending} in attesa`
    );
    
    lastLogTime.current = now;
  }, []);

  // Handle feedback updates
  const handleFeedback = useCallback((index, isHelpful) => {
    if (!mountedRef.current) return;

    setFeedbackStates(prev => {
      if (prev[index]?.isHelpful === isHelpful) return prev;

      const newStates = prev.map((state, i) => {
        if (i !== index) return state;

        const newState = {
          isHelpful,
          timestamp: Date.now(),
          userId: JSON.parse(localStorage.getItem("userId"))
        };

        feedbackMapRef.current.set(index, newState);
        return newState;
      });

      const activeCount = newStates.filter(f => f?.isHelpful !== null).length;
      console.log(`${isHelpful ? '✅' : '❌'} Feedback domanda ${index + 1} [${activeCount}/${newStates.length}]`);
      logStats(newStates);

      return newStates;
    });

    onFeedbackChange?.(index, isHelpful);
  }, [logStats, onFeedbackChange]);

  // Reset feedback
  const resetFeedback = useCallback(() => {
    if (!mountedRef.current) return;

    const activeCount = feedbackStates.filter(f => f?.isHelpful !== null).length;
    if (activeCount === 0) return;

    console.log('🔄 Reset feedback');
    feedbackMapRef.current.clear();
    
    const newStates = Array(feedbackStates.length).fill().map(() => ({
      isHelpful: null,
      timestamp: null,
      userId: null
    }));

    setFeedbackStates(newStates);
  }, [feedbackStates]);

  // Lifecycle management
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Handle changes in questions count
  useEffect(() => {
    if (!mountedRef.current) return;

    const currentLength = extractedQA.length;
    const prevLength = previousLengthRef.current;

    if (prevLength !== currentLength || feedbackStates.length !== currentLength) {
      console.log(`📝 Updating feedback count: ${prevLength} -> ${currentLength}`);
      previousLengthRef.current = currentLength;

      const newStates = Array(currentLength).fill().map((_, index) => {
        const existingFeedback = feedbackMapRef.current.get(index);
        return existingFeedback || {
          isHelpful: null,
          timestamp: null,
          userId: null
        };
      });

      setFeedbackStates(newStates);
    }
  }, [extractedQA.length, feedbackStates.length]);

  return {
    feedbackStates,
    handleFeedback,
    resetFeedback,
    activeFeedbackCount: feedbackStates.filter(f => f?.isHelpful !== null).length
  };
};

export default useFeedback;