import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "@redux/firebase";
import { toast } from "react-toastify";

const useSignInGoogle = mode => {
  const signInWithGoogle = async () => {
    if (mode === "signUp") localStorage.setItem("newUser", "true");

    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // trigger event for account creation
      if (mode === "signUp") {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'userSignUpGoogle', {
            'event_category': 'Account',
            'event_label': 'User Sign Up Google',
            'email': user.email
          });        
        } else {
          console.error("gtag is not defined");
        }

        toast.success("Registrazione con Google avvenuta con successo!");
      } else {
        toast.success("Login con Google avvenuto con successo!");
      }

    } catch (error) {
      console.error("Errore durante il login con Google: " + error.message);
    }
  };

  return {
    signInWithGoogle,
  };
};

export default useSignInGoogle;