import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Award } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import ExamSummary from './examSummary/examSummary';
import QuestionsSummary from './questionsSummary/questionsSummary';
import ExamReportAnswers from './examReportAnswers/examReportAnswers';
import './index.scss';

// Defining StudierAI's color palette
const colors = {
  primary: '#92C37E',
  secondary: '#E5E7EB',
  text: '#333333',
  background: '#FFFFFF',
  error: '#EF4444',
  nextExam: '#dee9e7',
  answerBackground: '#F0F9FF',
  feedbackBackground: '#FDF6B2',
  correct: '#16A342',
  partial: '#FCD34D',
  incorrect: '#DC2626',
};

const ExamResult = () => {
  const { state } = useLocation();

  const [filter, setFilter] = useState('all');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [partialAnswers, setPartialAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [skippedAnswers, setSkippedAnswers] = useState(0);
  
  const totalQuestions = useRef(state.messages.length);
  
  const calculatedData = useMemo(() => {
    let correctCount = 0;
    let partialCount = 0;
    let incorrectCount = 0;
    let skippedCount = 0;
    let totalScore = 0;
    let skippedQuestions = 0;

    const questions = state.messages.map((item, questionIndex) => {
      const [key, messages] = item;
      const questionScore = messages[2]?.score || 'skipped';
      const status = questionScore === 'skipped' ? 'skipped' : questionScore >= 7 ? 'correct' : questionScore >= 5 ? 'partial' : 'incorrect';

      if (status === 'skipped') {
        skippedQuestions += 1;
        skippedCount += 1;
      } else {
        totalScore += questionScore !== 'skipped' ? questionScore : 0;
      }

      if (status === 'correct') correctCount += 1;
      if (status === 'partial') partialCount += 1;
      if (status === 'incorrect') incorrectCount += 1;

      return { messages, status, questionIndex: questionIndex };
    });

    totalQuestions.current = state.messages.length - skippedQuestions;

    let average = totalQuestions.current === 0 ? 0 : totalScore / totalQuestions.current;
    
    // Per evitare numeri periodici
    if (average % 1 !== 0) {
      average = Math.floor(average * 10) / 10;
    }
    
    return {
      questions,
      correctCount,
      partialCount,
      incorrectCount,
      skippedCount,
      average
    };
  }, [state.messages]);

  useEffect(() => {
    setCorrectAnswers(calculatedData.correctCount);
    setPartialAnswers(calculatedData.partialCount);
    setIncorrectAnswers(calculatedData.incorrectCount);
    setSkippedAnswers(calculatedData.skippedCount);
    setFilteredQuestions(calculatedData.questions);
  }, [calculatedData]);

  useEffect(() => {
    const filtered = calculatedData.questions.filter((item) => {
      if (filter === 'all') return true;
      return item.status === filter;
    });
    setFilteredQuestions(filtered);
  }, [filter, calculatedData.questions]);

  const getBorderColor = useCallback((score) => {
    if (score >= 7) return colors.correct;
    if (score >= 5) return colors.partial;
    if (score < 5) return colors.incorrect;
    return colors.error;
  }, []);

  return (
    <div className="ExamResult-container__exam-result">
      <div 
        className="ExamResult-container__exam-result__content" 
        style={{ borderTopColor: getBorderColor(calculatedData.average) }}
      >
        <div className="ExamResult-container__exam-result__heading">
          <h2>
            <Award size={20} style={{ color: getBorderColor(calculatedData.average) }} /> Risultato Esame
          </h2>
        </div>
    
        <ExamSummary 
          score={calculatedData.average}
          totalQuestions={state.messages.length}
        />
    
        <QuestionsSummary 
          totalQuestions={state.messages.length}
          filter={filter}
          setFilter={setFilter}
          filteredQuestions={filteredQuestions}
          colors={colors}
          correctAnswers={correctAnswers}
          partialAnswers={partialAnswers}
          incorrectAnswers={incorrectAnswers}
          skippedAnswers={skippedAnswers}
        />

        <ExamReportAnswers 
          filteredQuestions={filteredQuestions}
          colors={colors}
          state={state}
          averageScore={calculatedData.average}
        />
      </div>
    </div>
  );
};

export default ExamResult;
