import React from "react";
import AccordionItem from "./accordionItem/accordionItem";
import "./accordion.scss";

const Accordion = () => {
  const faqItems = [
    {
      question: "Come StudierAI migliora il processo di apprendimento?",
      answer: "StudierAI utilizza l'Intelligenza Artificiale per personalizzare l'esperienza di studio. Offre ripetizioni assistite, simulazioni di interrogazioni e generazione di flashcard su misura. Queste funzionalità aiutano a ottimizzare il tempo di studio, rafforzare la comprensione e migliorare la memorizzazione, rendendo la preparazione più efficace e mirata."
    },
    {
      question: "Quali tipi di file posso utilizzare per creare flashcard con StudierAI?",
      answer: "StudierAI supporta una vasta gamma di formati di file per la creazione di flashcard. Per i documenti testuali: PDF, DOCX, e TXT. Per contenuti multimediali: MP4 per video-lezioni, MP3 per audio-lezioni, e immagini di appunti scritti a mano (JPG, PNG). Il nostro sistema AI analizza sia il contenuto testuale che multimediale, estraendo concetti chiave e trasformandoli in flashcard interattive. Per video e audio lezioni, il sistema trascrive automaticamente il contenuto e lo organizza in sezioni facilmente consultabili, rendendo il processo di studio più coinvolgente, efficiente e adatto a diversi stili di apprendimento."
    },
    {
      question: "Come vengono protetti i miei file e dati su StudierAI?",
      answer: "La sicurezza dei tuoi dati è la nostra priorità. StudierAI utilizza crittografia end-to-end per proteggere tutti i file caricati e implementa protocolli di sicurezza avanzati conformi al GDPR. I tuoi file sono archiviati in server sicuri con backup regolari, e hai il pieno controllo sulla loro condivisione e cancellazione. Inoltre, non condividiamo mai i tuoi dati con terze parti senza il tuo esplicito consenso."
    },
    {
      question: "Come funziona la simulazione di interrogazioni orali di StudierAI?",
      answer: "La nostra simulazione di interrogazioni orali utilizza tecnologie avanzate di Natural Language Processing. StudierAI formula domande basate sul materiale caricato, ascolta le tue risposte vocali e fornisce feedback in tempo reale. Questo sistema adattivo si concentra sulle aree in cui hai più bisogno di miglioramento, offrendo un'esperienza di preparazione personalizzata e realistica."
    },
    {
      question: "Quali sono le opzioni di supporto disponibili per gli utenti di StudierAI?",
      answer: "Offriamo un supporto completo agli utenti di StudierAI. Oltre alla nostra pagina FAQ e ai tutorial video, abbiamo un team di supporto dedicato raggiungibile via chat in-app, email o telefono. Inoltre, organizziamo regolarmente webinar e sessioni di Q&A per aiutare gli utenti a sfruttare al meglio la piattaforma."
    },
    {
      question: "StudierAI è adatto a tutti i livelli di istruzione?",
      answer: "Sì, StudierAI è progettato per adattarsi a diversi livelli di istruzione, dalla scuola media all'università. Il nostro sistema AI personalizza l'esperienza di apprendimento in base al livello e alle esigenze specifiche di ogni studente, rendendo lo studio efficace indipendentemente dal grado di istruzione."
    }
  ];

  return (
    <div className="Accordion">
      {faqItems.map((item, index) => (
        <AccordionItem 
          key={index} 
          question={item.question} 
          answer={item.answer} 
        />
      ))}
    </div>
  );
};

export default Accordion;