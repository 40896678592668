import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generateSpeech } from '../../../services/openaiApi';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../redux/firebase';
import { Send } from 'lucide-react';
import ChatModalFreeSubscription from './chatModalFreeSubscription/chatModalFreeSubscription';
import ExamCardHeader from './ExamCardHeader/ExamCardHeader';
import ExamProgress from './ExamProgress/ExamProgress';
import ExamContent from './ExamContent/ExamContent';
import FeedbackCard from './FeedbackCard/FeedbackCard';
import ChatCardForm from './chatCardForm/chatCardForm';
import useMessages from './hooks/useMessages';
import useChatForm from './chatCardForm/hooks/useChatForm';
import useShowModal from '../hooks/useShowModal';
import useSaveExam from '../chatHeader/hook/useSaveExam';
import Button from '../../../components/ui/button/button';
import './examCard.scss';

const ExamCard = ({ qa, currentQA, documentID }) => {
  const [examState, setExamState] = useState('waiting');
  const [isExamStarted, setIsExamStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [examProgress, setExamProgress] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [aiIconState, setAiIconState] = useState('stopped');
  const [chatMessages, setChatMessages] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [userAnswer, setUserAnswer] = useState(null);
  const [showExamFinishConfirmation, setShowExamFinishConfirmation] = useState(false);

  const inputRef = useRef(null);
  const audioContextRef = useRef(null);
  const audioBufferSourceRef = useRef(null);

  const { user } = useSelector(state => state.auth);
  const userPlan = user?.plan ?? "Base";
  const isFreeAccount = user?.role !== "Admin" && userPlan === "Base";
  const { showModal, closeModalHandler, incrementExamCount, examCount } = useShowModal(isFreeAccount, documentID);
  const {
    state,
    addMessage,
    deleteLoaderMessage,
    retryQuestion,
    skipQuestion,
    onCreateFeedback,
    examResult,
  } = useMessages(qa, currentQA, documentID);

  const { saveExam, isSaving } = useSaveExam(documentID, Array.from(state.messages));

  const { getFeedback } = useChatForm(
    state.messageID,
    addMessage,
    deleteLoaderMessage,
    onCreateFeedback,
    retryQuestion,
  );

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const firstUserAnswer = async () => {
    try {
      if(state.messages.size === 1 && user.examsAttempted === 0) {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'firstUserAnswer', {
            'event_category': 'Exam',
            'event_label': 'First User Answer',
            'email': user.email          
          });        
        } else {
          console.error("gtag is not defined");
        }
      }
    } catch(error) {
      console.error("Error getting userID for analytic event", error)
    }
  };

  useEffect(() => {
    if(audioBufferSourceRef.current) {
      setAiIconState('speaking');
    }
  }, [audioBufferSourceRef.current])
  
  const generateAndPlaySpeech = useCallback(async (answer) => {
    try {
      const arrayBuffer = await generateSpeech(answer.feedback);

      if (!audioContextRef.current) {
        console.error('AudioContext not initialized');
        return;
      }

      const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

      if (audioBufferSourceRef.current) {
        audioBufferSourceRef.current.stop();
      }

      audioBufferSourceRef.current = audioContextRef.current.createBufferSource();
      audioBufferSourceRef.current.buffer = audioBuffer;
      audioBufferSourceRef.current.connect(audioContextRef.current.destination);

      audioBufferSourceRef.current.onended = () => {
        setAiIconState('stopped');
      };

      // Su Safari, potrebbe essere necessario riprendere l'AudioContext
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      audioBufferSourceRef.current.start();
      setAiIconState('speaking');
    } catch (error) {
      console.error('Errore nella generazione o riproduzione dell\'audio:', error);
      setAiIconState('stopped');
    }
  }, [setAiIconState]);
  
  
  const processAnswer = useCallback(async (answer) => {
    try {
      setFeedback(answer);
      setExamState('answered');
      setChatMessages([]);
      setShowChat(false);

      // GA4 Event to count all users answers
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'usersAnswersCount', {
          'event_category': 'Exam',
          'event_label': 'User Answerered Question',
          'email': user.email
        });        
      } else {
        console.error("gtag is not defined");
      }

      await generateAndPlaySpeech(answer);
    } catch (error) {
      console.error("Error processing answer:", error);
    }
  }, [generateAndPlaySpeech, user.email]);
  

  const sendChatMessage = useCallback((answer) => {
    if(!answer) return;
    setChatMessages((prevMessages) => [...prevMessages, { text: answer, sender: 'user' }]);
  }, []);

  const startExam = useCallback(() => {
    try {
      setIsExamStarted(true);
      setExamState('listening');
      setExamProgress(100 / qa.length);
    } catch (error) {
      console.error("Error starting the exam:", error);
    }
  }, [qa.length]);

  const handleCancelFinishExam = () => {
    setShowExamFinishConfirmation(false);
  };
  
  const retryCurrQuestion = useCallback(async () => {
    try {
      if(examState === 'processing' || (aiIconState !== 'speaking' && aiIconState !== 'stopped')) return;
      
      //Stop speaking from previous question
      if(audioContextRef.current) {
        audioContextRef.current.suspend();
        
        setAiIconState('stopped');
      }
      
      await getFeedback({}, true);
      setExamState('listening');
      setFeedback(null);
      setShowChat(false);
    } catch (error) {
      console.error("Error retrying the question:", error);
    }
  }, [getFeedback, examState, aiIconState]);

  const handleExamCompletion = useCallback(() => {
    examResult();
    incrementExamCount();
  }, [examResult, incrementExamCount]);

  const handleFinishExam = useCallback(() => {
    if(examState === 'processing' || (aiIconState !== 'speaking' && aiIconState !== 'stopped') || examState === 'waiting') return;

    if(audioContextRef.current) {
      audioContextRef.current.suspend();
      
      setAiIconState('stopped');
    }

    setShowExamFinishConfirmation(true);
  }, [examState, aiIconState]);

  const handleConfirmFinishExam = useCallback(() => {
    setShowExamFinishConfirmation(false);
    handleExamCompletion();

    if (isFreeAccount && examCount === 0) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'userAnsweredAllFreeQuestions', {
          'event_category': 'Exam',
          'event_label': 'User Answerered All Free Questions',
          'email': user.email
        });        
      } else {
        console.error("gtag is not defined");
      }
    }

    if (typeof window.gtag === 'function') {
      window.gtag('event', 'viewExamResultPage', {
        'event_category': 'View',
        'event_label': 'view Exam Result Page',
        'email': user.email    
      });        
    } else {
      console.error("gtag is not defined");
    }
  }, [examCount, handleExamCompletion, isFreeAccount, user.email]);

  const nextQuestion = useCallback(() => {
    try {
      if(feedback) {
        if(examState === 'processing' || (aiIconState !== 'speaking' && aiIconState !== 'stopped')) return;
      }

      if(audioContextRef.current) {
        audioContextRef.current.suspend();
        
        setAiIconState('stopped');
      }

      const totalQuestions = qa.length;
      const validTotalQuestions = Number.isInteger(totalQuestions) && totalQuestions > 0 ? totalQuestions : 0;
      const validCurrentIndex = Number.isInteger(currentQuestion) && currentQuestion >= 0 ? currentQuestion : 0;

      const progress = validTotalQuestions > 0 ? ((validCurrentIndex + 2) / validTotalQuestions) * 100 : 0;

      if (currentQuestion < qa.length - 1) {
        setCurrentQuestion((prev) => prev + 1);
        setExamProgress(progress);
        setExamState('listening');
        setFeedback(null);
        setShowChat(false);
      } else {
        setExamProgress(100);
        setExamState('completed');
        handleConfirmFinishExam();
      }
    } catch (error) {
      console.error("Error moving to the next question:", error);
    }
  }, [qa.length, currentQuestion, handleConfirmFinishExam, examState, aiIconState, feedback]);

  const isInProgress = !!(currentQA && currentQA.length > 0);

  return (
    <div className="Examcard-container">
      {showModal && <ChatModalFreeSubscription onClose={closeModalHandler} examCount={examCount} />}
      <ExamCardHeader
        isExamStarted={isExamStarted}
        handleConfirmFinishExam={handleConfirmFinishExam}
        saveExam={saveExam}
        isSaving={isSaving}
        isInProgress={isInProgress}
        audioRef={audioContextRef}
        examState={examState}
        aiIconState={aiIconState}
        feedback={feedback}
        setAiIconState={setAiIconState}
        isRecording={isRecording}
      />

      <main className="Examcard-container__examcard-main">
        <div className='Examcard-container__exam-container'>
          <ExamProgress
            isExamStarted={isExamStarted}
            qa={qa}
            currentQA={currentQuestion}
            progress={examProgress}
          />

          <ExamContent
            examState={examState}
            isExamStarted={isExamStarted}
            qa={qa}
            currentQA={currentQuestion}
            isRecording={isRecording}
            onSkipQuestion={skipQuestion}
            nextQuestion={nextQuestion}
            startExam={startExam}
            aiIconState={aiIconState}
            userAnswer={userAnswer}
          />

          {isFreeAccount && examCount >= 1 && null}

          {isFreeAccount && examCount < 1 && (
            <div className="ExamCard__footer">
              <ChatCardForm
                isExamFinished={state.isExamFinished}
                messageID={state.messageID}
                onAddMessage={addMessage}
                onCreateFeedback={onCreateFeedback}
                onFeedbackError={deleteLoaderMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setExamState={setExamState}
                startExam={startExam}
                examState={examState}
                setShowChat={setShowChat}
                firstUserAnswer={firstUserAnswer}
                setAiIconState={setAiIconState}
                setUserAnswer={setUserAnswer}
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={Array.from(state.messages)}
                showChat={showChat}
                isExamStarted={isExamStarted}
              />
            </div>
          )}

          {userPlan === "Premium" && (
            <div className="ExamCard__footer">
              <ChatCardForm
                isExamFinished={state.isExamFinished}
                messageID={state.messageID}
                onAddMessage={addMessage}
                onCreateFeedback={onCreateFeedback}
                onFeedbackError={deleteLoaderMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setExamState={setExamState}
                startExam={startExam}
                examState={examState}
                setShowChat={setShowChat}
                firstUserAnswer={firstUserAnswer}
                setAiIconState={setAiIconState}
                setUserAnswer={setUserAnswer}
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={Array.from(state.messages)}
                showChat={showChat}
                isExamStarted={isExamStarted}
              />
            </div>
          )}

          {showExamFinishConfirmation && (
            <div className="ExamFinishToastContainer">
              <div className="ExamFinishToastContainer__examFinishToast">
                <h2 className="ExamFinishToastContainer__title">Conferma</h2>
                <p className="ExamFinishToastContainer__message">Vuoi andare direttamente al resoconto?</p>
                <div className="ExamFinishToastContainer__buttons">
                  <Button onClick={handleConfirmFinishExam} variant="primary" size="md">
                    Sì, termina l'esame
                  </Button>
                  <Button onClick={handleCancelFinishExam} variant="secondary" size="md">
                    No, continua l'esame
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <FeedbackCard
          isExamStarted={isExamStarted}
          feedback={feedback}
          nextQuestion={nextQuestion}
          retryCurrQuestion={retryCurrQuestion}
          handleFinishExam={handleFinishExam}
          examState={examState}
          aiIconState={aiIconState}
          isRecording={isRecording}
          currentQuestion={currentQuestion + 1}
          qa={qa}
        />
      </main>
    </div>
  );
};

export default ExamCard;