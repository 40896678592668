import React from "react";
import { motion } from "framer-motion";
import shadow from '../../../../assets/icons/shadow.png';
import greenIconBg from '../../../../assets/icons/green-icon-bg.png';
import blueRight from '../../../../assets/icons/blue-right.png';
import blueMiddle from '../../../../assets/icons/blue-middle.png';
import pinkTop from '../../../../assets/icons/pink-top.png';
import pinkLeft from '../../../../assets/icons/pink-left.png';
import bottomPink from '../../../../assets/icons/bottom-pink.png';
import intersect from '../../../../assets/icons/Intersect.png';
import greenLeft from '../../../../assets/icons/green-left.png';
import highlight from '../../../../assets/icons/highlight.png';
import "./speaker-icon.scss";

const SpeakerIcon = ({ aiIconState, examState }) => {
  return (
    <div className={`speaker-animation ${examState === 'answered' && 'speaker-animation__answer-complete'}`}>
      <div className="toolbar">
        <div className="animation-area">
          <motion.div className={`animation-wrapper ${aiIconState === 'speaking' ? 'pulse-animation' : ''}`}>
            <img src={shadow} alt="shadow" className="image-shadow" />
            <img src={greenIconBg} alt="icon-bg" className="icon-bg" />

            <motion.img
              src={blueRight}
              alt="blue-right"
              className={`image-rotate blue-right ${aiIconState === 'processing' ? 'rotate-animation' : ''}`}
              animate={{
                rotate: 360,
                filter: ["hue-rotate(-50deg)", "hue-rotate(720deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />

            <motion.img
              src={blueMiddle}
              alt="blue-middle"
              className={`image-rotate blue-middle ${aiIconState === 'processing' ? 'rotate-animation' : ''}`}
              animate={{
                rotate: 360,
                filter: ["hue-rotate(0deg)", "hue-rotate(-150deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />

            <motion.img
              src={pinkTop}
              alt="pink-top"
              className={`image-rotate pink-top ${aiIconState === 'processing' ? 'rotate-animation' : ''}`}
              animate={{
                rotate: -360,
                filter: ["hue-rotate(60deg)", "hue-rotate(-270deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />

            <motion.img
              src={pinkLeft}
              alt="pink-left"
              className={`image-rotate pink-left ${aiIconState === 'processing' ? 'rotate-animation' : ''}`}
              animate={{
                rotate: 360,
                filter: ["hue-rotate(300deg)", "hue-rotate(-220deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />

            <motion.img
              src={intersect}
              alt="intersect"
              className={`image-rotate intersect ${aiIconState === 'processing' ? 'rotate-animation' : ''}`}
              animate={{
                rotate: -360,
                filter: ["hue-rotate(720deg)", "hue-rotate(0deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />

            <motion.img
              src={greenLeft}
              alt="green-left"
              className={`image-rotate green-left ${aiIconState === 'processing' ? 'rotate-animation' : ''}`}
              animate={{
                rotate: 360,
                filter: ["hue-rotate(50deg)", "hue-rotate(180deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />

            <motion.img
              src={bottomPink}
              alt="bottom-pink"
              className={`image-rotate bottom-pink ${aiIconState === 'processing' ? 'rotate-animation' : ''}`}
              animate={{
                rotate: -360,
                filter: ["hue-rotate(230deg)", "hue-rotate(0deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />

            <motion.img
              src={highlight}
              alt="highlight"
              className="image-rotate highlight"
              animate={{
                rotate: 360,
                filter: ["hue-rotate(230deg)", "hue-rotate(0deg)"],
              }}
              transition={{ duration: 12, ease: "linear", repeat: Infinity }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SpeakerIcon;