import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Gift, Users, Share2 } from 'lucide-react';
import "./referralCode.scss";

const ReferralCode = () => {
  const { user } = useSelector(state => state.user);
  const navigate = useNavigate();
  const [friendsInvited, setFriendsInvited] = useState(0); // Numero di persone invitate
  const [amountEarned, setAmountEarned] = useState(0); // Ammontare totale guadagnato in €

  const goToUpgrade = () => {
    navigate("/home/piani");
  };
  
  const API_URL = process.env.NODE_ENV === "development" 
    ? process.env.REACT_APP_API_URL_DEVELOPMENT 
    : process.env.REACT_APP_API_URL_PRODUCTION;

  useEffect(() => {
    const fetchPromotionDetails = async () => {
      if (user.referralCode) {
        try {
          const response = await fetch(`${API_URL}/fetch-promotion-details?referralCode=${user.referralCode}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) throw new Error('Error getting promotion code');
          const data = await response.json();

          if (data.data && data.data.length > 0) {
            const promotionCode = data.data[0];
            setAmountEarned(promotionCode.times_redeemed * 5); // Ammontare in €
            setFriendsInvited(promotionCode.times_redeemed); // Numero di inviti
          }
        } catch (error) {
          console.error("Error fetching promotion details:", error);
        }
      }
    };
  
    fetchPromotionDetails();
  }, [user.referralCode, API_URL]);

  const handleShare = useCallback(() => {
    const shareCode = user.referralCode;
    navigator.clipboard.writeText(shareCode).then(() => {
      if (window.Notification && Notification.permission === "granted") {
        new Notification("StudierAI", { body: "Link di invito copiato negli appunti!" });
      } else {
        alert("Link di invito copiato negli appunti!");
      }
    }).catch(err => {
      console.error("Errore durante la copia negli appunti:", err);
      alert("Si è verificato un errore durante la copia del link. Per favore, prova di nuovo.");
    });
  }, [user.referralCode]);

  // Funzione per formattare i numeri con separatori di migliaia
  const formatNumber = (num) => num.toLocaleString('it-IT');

  return (
    <div className="ReferralCode">
      <div className="ReferralCode__container">
        <div className="ReferralCode__card">
          <div className="ReferralCode__card__top-card">
            <h3 className="ReferralCode__card__title">
              Guadagna 5€ per ogni amico che si iscrive a StudierAI Premium!
            </h3>
            
            <div className="value-proposition">
              <div className="value-proposition__item">
                <Users />
                <p>I tuoi amici riceveranno uno sconto di 2€ per l'iscrizione all'abbonamento premium!</p>
              </div>
              <div className="value-proposition__item">
                <Gift />
                <p>Tu ricevi 5€ in crediti Amazon per ogni amico iscritto</p>
              </div>
            </div>
          </div>
          
          <div className="ReferralCode__card__statistics-card">
            <div className="ReferralCode__card__statistics-card__ongoing-container">
              <h3 className="ReferralCode__card__statistics-card__referral-label">
                Statistiche continue
              </h3>
              <div className="ReferralCode__card__statistics-card__ongoing-row">
                {/* Numero di amici invitati */}
                <div className="ReferralCode__card__statistics-card__ongoing-row__container">
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__subtitle">
                    RICOMPENSE TOTALI
                  </span>
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__amount">
                    {user.plan === 'Premium' ? formatNumber(friendsInvited) : 0}
                  </span>
                </div>

                {/* Ammontare totale guadagnato in € */}
                <div className="ReferralCode__card__statistics-card__ongoing-row__container">
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__subtitle">
                    VALORE COMPLESSIVO
                  </span>
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__amount">
                    {user.plan === 'Premium' ? `${formatNumber(amountEarned)}€` : "0€"}
                  </span>
                </div>
              </div>
            </div>
            
            {user.plan === 'Premium' ? (
              <div className="ReferralCode__card__statistics-card__referralCode-container">
                <h3 className="ReferralCode__card__statistics-card__referralCode-container__referral-label">
                  Codice referral
                </h3>
                <div className="referral-code-input-group">
                  <input 
                    className="ReferralCode__card__statistics-card__referralCode-container__input"
                    value={user.referralCode}
                    readOnly
                    onClick={handleShare}
                  />
                  <button 
                    className="share-button" 
                    onClick={handleShare}
                    aria-label="Copia codice"
                  >
                    <Share2 />
                  </button>
                </div>
              </div>
            ) : (
              <div className="ReferralCode__card__statistics-card__upgrade-container">
                <h3 className="ReferralCode__card__statistics-card__upgrade-container__upgrade-label">
                  Sblocca il codice referral
                </h3>
                <button 
                  className="ReferralCode__card__statistics-card__upgrade-container__upgrade-btn"
                  onClick={goToUpgrade}
                >
                  Iscriviti a Premium
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="ReferralCode__container__giftcards-container">
          <h3 className="ReferralCode__card__title">Giftcards</h3>
          <span className="ReferralCode__container__giftcards-container__subtitle">
            Le ricompense appariranno qui sotto ogni primo giorno del mese.
          </span>
          <div className="ReferralCode__container__giftcards-container__giftcards-table">
            <div className="ReferralCode__container__giftcards-container__giftcards-table__giftcard">
              Nessuna giftcard disponibile
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCode;
