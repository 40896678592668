import React from 'react';
import styled from 'styled-components';
import { FaRegSadCry, FaRegSmileBeam } from 'react-icons/fa';
import { FiBookOpen, FiSearch, FiRepeat, FiCheckCircle, FiEdit, FiClock } from 'react-icons/fi';

const StatsContainer = styled.section`
  padding: 6rem 2rem;
  background-color: var(--grayscale-color-100);
  border-radius: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const MainTitle = styled.h2`
  font-size: 3.2rem;
  margin-bottom: 1rem;
  color: #1f2937;
  font-weight: 700;
`;

const Subtitle = styled.p`
  font-size: 1.6rem;
  color: #4b5563;
  margin-bottom: 4rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const StatsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

const StatsCard = styled.div`
  background-color: #ffffff;
  border-radius: 16px;
  padding: 2.4rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  width: 100%;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  }
`;

const IconWrapper = styled.div`
  background-color: ${({ bgcolor }) => bgcolor};
  color: #ffffff;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
`;

const SectionTitle = styled.h3`
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  color: #1f2937;
  font-weight: 600;
`;

const StatsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  flex: 1;
`;

const StatsItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  color: #374151;
`;

const ItemIcon = styled.div`
  color: ${({ iconcolor }) => iconcolor};
  font-size: 2rem;
  margin-right: 1.2rem;
`;

const StatsTotal = styled.p`
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 2rem;
  color: ${({ color }) => color};
`;

const StudierAIStats = () => {
  return (
    <StatsContainer id="studierai-stats">
      <MainTitle>Studia meno, impara di più</MainTitle>
      <Subtitle>
        Supera il tuo prossimo esame senza sacrificare le tue notti sui libri
      </Subtitle>

      <StatsSection>
        <StatsCard>
          <IconWrapper bgcolor="#ef4444">
            <FaRegSadCry />
          </IconWrapper>
          <SectionTitle>Senza StudierAI</SectionTitle>
          <StatsList>
            <StatsItem>
              <ItemIcon iconcolor="#ef4444">
                <FiBookOpen />
              </ItemIcon>
              Appunti disorganizzati e difficili da gestire
            </StatsItem>
            <StatsItem>
              <ItemIcon iconcolor="#ef4444">
                <FiSearch />
              </ItemIcon>
              Tempo perso a cercare informazioni
            </StatsItem>
            <StatsItem>
              <ItemIcon iconcolor="#ef4444">
                <FiRepeat />
              </ItemIcon>
              Ripetizioni inefficaci e stressanti
            </StatsItem>
          </StatsList>
          <StatsTotal color="#ef4444"><strong>Ore di studio eccessive</strong></StatsTotal>
        </StatsCard>

        <StatsCard>
          <IconWrapper bgcolor="#349559">
            <FaRegSmileBeam />
          </IconWrapper>
          <SectionTitle>Con StudierAI</SectionTitle>
          <StatsList>
            <StatsItem>
              <ItemIcon iconcolor="#349559">
                <FiCheckCircle />
              </ItemIcon>
              Metodo di studio personalizzato ed efficace
            </StatsItem>
            <StatsItem>
              <ItemIcon iconcolor="#349559">
                <FiEdit />
              </ItemIcon>
              Appunti organizzati e accessibili ovunque
            </StatsItem>
            <StatsItem>
              <ItemIcon iconcolor="#349559">
                <FiClock />
              </ItemIcon>
              Ottimizzazione del tempo e meno stress
            </StatsItem>
          </StatsList>
          <StatsTotal color="#349559"><strong>Ore di studio ottimizzate!</strong></StatsTotal>
        </StatsCard>
      </StatsSection>
    </StatsContainer>
  );
};

export default StudierAIStats;
