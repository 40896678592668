import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { toast } from 'react-toastify'

let didInit = false
const useResendEmail = () => {
  const auth = getAuth()
  const [user, loading] = useAuthState(auth)
  const [attempts, setAttempts] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  // Funzione per inviare l'email di verifica
  const sendVerificationEmail = useCallback(async () => {
    try {
      if (!user) return toast.error('Utente non trovato')
      setIsLoading(true)

      await sendEmailVerification(user)
      setIsLoading(false)
      toast.success('Email di verifica inviata con successo')
      navigate('/controlla-verifica-email', { replace: true })
     
      // trigger event for account creation
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'userSignUpNormal', {
          'event_category': 'Account',
          'event_label': 'User Sign Up Normal',
          'email': user.email
        });        
      } else {
        console.error("gtag is not defined");
      }
      
    } catch (error) {
      setAttempts(prev => prev + 1)
      setIsLoading(false)
      toast.error("Errore nell'invio della email di verifica: " + error.message)
    }
  }, [navigate, user])

  useEffect(() => {
    if (!didInit && user) {
      didInit = true
      sendVerificationEmail()
    }
  }, [sendVerificationEmail, user])

  // Funzione per rinviare l'email di verifica
  const onSendResetEmail = () => sendVerificationEmail()

  return { attempts, isLoading, loading, onSendResetEmail }
}

export default useResendEmail