import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './VideoContainer.scss';

const VideoContainer = ({ videoRef }) => {
  const localVideoRef = useRef(null);

  useEffect(() => {
    if (videoRef) {
      videoRef.current = localVideoRef.current;
    }

    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    const initializePlayer = () => {
      if (window.YT && localVideoRef.current) {
        new window.YT.Player(localVideoRef.current, {
          videoId: '0EbfTKC1HPI',
          playerVars: {
            autoplay: 0,
            controls: 1,
            rel: 0,
            modestbranding: 1,
          },
          events: {
            'onReady': onPlayerReady,
          }
        });
      }
    };

    const onPlayerReady = (event) => {
      const qualities = event.target.getAvailableQualityLevels();
      const hdQuality = qualities.includes('hd1080') ? 'hd1080' : 
                        qualities.includes('hd720') ? 'hd720' : 
                        qualities[0];
      event.target.setPlaybackQuality(hdQuality);
    };

    loadYouTubeAPI();

    if (window.YT) {
      initializePlayer();
    } else {
      window.onYouTubeIframeAPIReady = initializePlayer;
    }
  }, [videoRef]);

  return (
    <div className="VideoContainer">
      <div className="video-wrapper">
        <div ref={localVideoRef} id="player"></div>
      </div>
    </div>
  );
};

VideoContainer.propTypes = {
  videoRef: PropTypes.object,
};

export default VideoContainer;