import React from "react";
import Slider from "react-slick";
import Documents from "@assets/images/documents.png";
import Flashcards from "@assets/images/flashcards.png";
import Chat from "@assets/images/chat.png";
import Resoconto from "@assets/images/resoconto.png"; 

import FunctionalityItem from "./functionalityItem/functionalityItem";

/* styles */
import "./functionality.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Functionality = () => {


  const settings = {
    dots: false, // Rimuoviamo i dots
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true, 
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };



  return (
    <div id="functionality" className="Functionality">
      <div className="container">
        <Slider {...settings}>
          <FunctionalityItem
            title="Carica il tuo materiale"
            text="Carica qualsiasi documento (PDF, DOCX,TXT...), foto di appunti, video e audio lezioni con un clic (in tutte le lingue). StudierAI li trasforma subito in un percorso di apprendimento su misura per te."            
            image={Documents}
          />
          <FunctionalityItem
            title="Ripassa con flashcard"
            text=" Visualizza appunti, riassunti, schemi, mappe e qualsiasi tipo di documento in flashcard interattive per un ripasso efficace e veloce."
            image={Flashcards}
          />
          <FunctionalityItem
            title="Simula interrogazioni"
            text="StudierAI ti aiuta a perfezionare le tue risposte e a ridurre l'ansia per tutte le materie in qualsiasi lingua. Esercitati oralmente con interrogazioni simulate e ricevi feedback immediati. "
            image={Chat}
          />
          <FunctionalityItem
            title="Comprendi il tuo voto"
            text="StudierAI ti offre a fine esame un resoconto mirato per aiutarti a correggere gli errori e a consolidare le tue conoscenze. Un'analisi concreta per risultati concreti."
            image={Resoconto}
          />
        </Slider>
      </div>
    </div>
  );
};

export default Functionality;
