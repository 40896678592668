import React from "react";
import Heading from "@ui/heading/heading";
import "./faq.scss";
import Accordion from "./accordion/accordion";
import "./faq.scss";

const Faq = () => {
  return (
    <section id="faq" className="Faq">
      <div className="container">
        <div className="row justify-center g-3 g-lg-4">
          <div className="col-lg-8">
            <Heading title="Domande Frequenti" heading="h3" className="Faq__title" />
            <p className="Faq__subtitle">Trova risposte alle domande più comuni su StudierAI</p>
          </div>
          <div className="col-lg-8">
            <Accordion />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;