import React, { useState, useCallback, useEffect } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { TbEdit } from "react-icons/tb";
import PropTypes from "prop-types";
import clsx from "clsx";
import Heading from "@ui/heading/heading";
import Input from '@ui/input/input';
import ItemQA from "./itemQA/itemQA";
import "./documentQA.scss";


const DocumentQA = ({
 index,
 isActive,
 id,
 title,
 extractedQA,
 documentsQA,
 onUpdateTitle,
 feedbackStates,
 onFeedback
}) => {
 const [isChanging, setIsChanging] = useState(false);
 const [inputValue, setInputValue] = useState(title);


 // Aggiorna l'input quando cambia il titolo da props
 useEffect(() => {
   setInputValue(title);
 }, [title]);


 // Ordina le domande in ordine crescente alfanumerico
 const sortedQA = [...extractedQA].sort((a, b) => a.question.localeCompare(b.question));
 const position = isActive ? 0 : index === 0 ? 1 : index;


 const handleEditTitle = useCallback(() => {
   if (isChanging) {
     const newTitle = inputValue.trim();
     if (newTitle && title !== newTitle) {
       console.log('📝 Aggiornamento titolo temporaneo:', {
         id,
         oldTitle: title,
         newTitle
       });
      
       onUpdateTitle(newTitle);
     } else {
       // Se il titolo è vuoto o non è cambiato, ripristina il valore precedente
       setInputValue(title);
     }
     setIsChanging(false);
   } else {
     setIsChanging(true);
   }
 }, [isChanging, title, inputValue, id, onUpdateTitle]);


 const changingInputValue = useCallback((e) => {
   const value = e.target.value;
   setInputValue(value.charAt(0).toUpperCase() + value.slice(1));
 }, []);


 const handleKeyPress = useCallback((e) => {
   if (e.key === 'Enter') {
     handleEditTitle();
   } else if (e.key === 'Escape') {
     setInputValue(title);
     setIsChanging(false);
   }
 }, [handleEditTitle, title]);


 const handleBlur = useCallback(() => {
   handleEditTitle();
 }, [handleEditTitle]);


 return (
   <div
     style={{ "--elPos": position }}
     className={clsx("DocumentQA", isActive && "DocumentQA--active")}
   >
     <header className="DocumentQA__header">
       <div className="DocumentQA__title-container">
         {isChanging ? (
           <Input
             className="DocumentQA__title-Input"
             type="text"
             value={inputValue}
             onChange={changingInputValue}
             onKeyDown={handleKeyPress}
             onBlur={handleBlur}
             autoFocus
           />
         ) : (
           <Heading heading="h6" title={title} />
         )}
         <div className="DocumentQA__actions">
           {isChanging
             ? <IoMdCheckmark className="DocumentQA__saveIcon" onClick={handleEditTitle}/>
             : <TbEdit className="DocumentQA__editIcon" onClick={handleEditTitle}/>
           }
         </div>
       </div>
     </header>


     <div className="DocumentQA__body">
       {sortedQA.length > 0 ? (
         <ul className="DocumentQA__list">
           {sortedQA.map((qa, questionIndex) => (
             <ItemQA
               key={`${qa.question}-${questionIndex}`}
               questionNum={questionIndex + 1}
               question={qa.question}
               answer={qa.answer || qa.answers || ""}
               feedback={feedbackStates[questionIndex]}
               onFeedback={(isHelpful) => onFeedback(questionIndex, isHelpful)}
             />
           ))}
         </ul>
       ) : (
         <p>Nessuna domanda estratta</p>
       )}
     </div>
   </div>
 );
};


DocumentQA.propTypes = {
 index: PropTypes.number.isRequired,
 isActive: PropTypes.bool.isRequired,
 id: PropTypes.string,
 title: PropTypes.string.isRequired,
 extractedQA: PropTypes.arrayOf(
   PropTypes.shape({
     question: PropTypes.string.isRequired,
     answer: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
     answers: PropTypes.oneOfType([PropTypes.string, PropTypes.any])
   })
 ).isRequired,
 documentsQA: PropTypes.array.isRequired,
 onUpdateTitle: PropTypes.func.isRequired,
 feedbackStates: PropTypes.array,
 onFeedback: PropTypes.func
};


export default DocumentQA;


