import { useCallback, useLayoutEffect, useState } from "react";
import { getCheckoutUrl, getPremiumStatus, getPortalUrl } from "@lib/stripeFn";
import { getAuth } from "firebase/auth";

import { toast } from "react-toastify";

const usePayment = () => {
  const [isPremium, setIsPremium] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();
  const userId = auth.currentUser?.uid;

  // redirect to checkout page
  const upgradePlan = async priceId => {
    try {
      setIsLoading(true);
      const checkout = await getCheckoutUrl(priceId);
      window.location.href = checkout;
      setIsLoading(false);
    } catch (error) {
      toast.error("Errore durante il pagamento");
      setIsLoading(false);
    }
  };

  // get status of the payment
  const getStatusPayment = useCallback(async () => {
    setIsPremium(await getPremiumStatus());

    // trigger event for switch to premium plan
    if(isPremium && userId) { 
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'premiumSubscription', {
          'event_category': 'Account',
          'event_label': 'Premium Subscription',
          'user_id': userId    
        });        
      } else {
        console.error("gtag is not defined");
      }
    };
  }, [isPremium, userId]);

  // check status of the payment
  useLayoutEffect(() => {
    getStatusPayment();
  }, [getStatusPayment]);

  // manage subscription
  const manageSubscription = async () => {
    try {
      setIsLoading(true);
      const portalUrl = await getPortalUrl();
      window.location.href = portalUrl;
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return { isLoading, isPremium, upgradePlan, manageSubscription };
};

export default usePayment;