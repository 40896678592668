import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import useStickyHeader from "./hook/useStickyHeader";

import Logo from "@ui/logo/logo";
import Button from "@ui/button/button";
import NavHashItem from "@components/ui/navbar/navHashItem/navHashItem";
import HamburgerMenu from "@containers/layout/header/hamburgerMenu/hamburgerMenu";
import LandingMenu from "@containers/landingMenu/landingMenu";
import DropMenudown from "./DropMenudown/Dropmenudown"; // Import corretto

/* styles */
import "./header.scss";

const LandingHeader = () => {
  const navigate = useNavigate();
  const { isSticky } = useStickyHeader();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigateToHome = () => {
    navigate('/'); 
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    document.body.classList.add("no-scroll"); // Blocca lo scroll
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll verso l'alto
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    document.body.classList.remove("no-scroll"); // Sblocca lo scroll
  };

  useEffect(() => {
    return () => {
      // Rimuove la classe no-scroll se il componente viene smontato
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <header
      className={clsx(
        "LandingHeader",
        isSticky && "LandingHeader--sticky",
        isMenuOpen && "LandingHeader--hidden" // Nasconde l'header quando il menu è aperto
      )}
    >
      <div className="container">
        <div className="LandingHeader__content">
          <div className="flex items-center">
            <div onClick={navigateToHome}>
              <Logo />
            </div>
            <nav className="LandingHeader__navbar">
              <NavHashItem to="/#functionality">Come Funziona?</NavHashItem>
              <NavHashItem to="/#video-section">Tutorial</NavHashItem>
              <NavHashItem to="/#price">Piani e Prezzi</NavHashItem>
              <NavHashItem to="/#studierai-stats">Benefici</NavHashItem>
              <DropMenudown />
            </nav>
          </div>

          <div className="LandingHeader__right">
            <HamburgerMenu onClick={handleMenuOpen}>
              <LandingMenu onClose={handleMenuClose} />
            </HamburgerMenu>
            <Button
              size="sm"
              variant="primary"
              onClick={() => navigate("/signIn")}
              className="LandingHeader__sign-up-mobile"
            >
              Accedi
            </Button>
            <Button
              size="sm"
              variant="tertiary"
              onClick={() => navigate("/signIn")}
            >
              Accedi
            </Button>
            <Button
              size="sm"
              variant="primary"
              onClick={() => navigate("/signUp")}
            >
              Registrati Gratis
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
