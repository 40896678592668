import PdfIcon from "@assets/icons/pdf.png";
import ImageIcon from "@assets/icons/photo.png";
import DocxIcon from "@assets/icons/docx.png";
import PptxIcon from "@assets/icons/pptx.png";
import PagesIcon from "@assets/icons/pages.png";
import KeyIcon from "@assets/icons/keynote.png";
import File from "@assets/icons/file.png";
import { processDocument } from "@services/openaiApi";

export const getTypeFile = fileType => {
  if (fileType && fileType.split("/").length > 1) return fileType.split("/")[1];
  return fileType;
};

export const getFileIcon = fileType => {
  switch (getTypeFile(fileType)) {
    case "pdf":
      return PdfIcon;
    case "jpeg":
    case "png":
    case "jpg":
      return ImageIcon;
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return DocxIcon;
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
      return PptxIcon;
    case "vnd.apple.pages":
      return PagesIcon;
    case "vnd.apple.keynote":
      return KeyIcon;
    default:
      return File;
  }
};

const getRawExtractedQA = async extractedText => {
  try {
    const rawExtractedQA = await processDocument(extractedText);
    return rawExtractedQA;
  } catch (error) {
    throw error;
  }
};

const formatExtractedQA = (rawExtractedQA, document) => {
  const qaRegex = /__Domanda__: (.*?)(?:\n{1,2}|__Risposta__: )__Risposta__: (.*?)(?=\n{1,2}__Domanda__:|$)/gs;

  let match;
  const questionsLength = [];
  const extractedQA = [];
  let questionIndex = 1;
  while ((match = qaRegex.exec(rawExtractedQA)) !== null) {
    extractedQA.push({
      question: match[1],
      answer: match[2],
    });
    const question = {
      'Question':  `${questionIndex} - ${match[1].length} caratteri`
    }
    questionsLength.push(question);

    
    questionIndex += 1 ;
  }

  //GA4 trigger event for questions length
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'docQuestionsLength', {
      'event_category': 'property',
      'event_label': 'get document questions length',
      'questionsList' : JSON.stringify(questionsLength),
    });        
  } else {
    console.error("gtag is not defined");
  }

  console.log(questionsLength);
  return {
    id: Date.now().toString(),
    title: document.name.split(".")[0],
    type: document.type,
    text: document.extractedText,
    extractedQA,
  };
};

export const getExtractedQA = async documents => {
  if(documents.length > 1) {
    try {
      const combinedText = documents
        .filter(doc => !doc.errorMessage) 
        .map(doc => doc.extractedText)   
        .join(' ');                      
  
      if (!combinedText) return [];
  
      const rawExtractedQA = await getRawExtractedQA(combinedText);
  
      const documentQA = formatExtractedQA(rawExtractedQA, {
        name: 'Documento',   
        type: 'multiple',            
        extractedText: combinedText  
      });
  
      return [documentQA]; 
    } catch (error) {
      throw error;
    }
  } else {
    try {
      let documentsQA = [];

      for (const document of documents) {
        if (document.errorMessage) continue;
  
        const rawExtractedQA = await getRawExtractedQA(document.extractedText);
        const documentQA = formatExtractedQA(rawExtractedQA, document);
        documentsQA = [...documentsQA, documentQA];
      }
  
      return documentsQA;
    } catch (error) {
      throw error;
    }
  }
};