import axios from "axios";

let API_URL;

if (process.env.NODE_ENV === "development") {
  API_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
} else {
  API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
}

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to process documents
const processDocument = async documentText => {
  try {
    const requestBody = { documentText };
    const response = await axiosInstance.post("/process-document", requestBody);

    if (response.status === 200 && response.data) {
      return response.data.assistantMessage;
    } else {
      console.error(`API request unexpected response:`, response);
      throw new Error(`API request unexpected response with status ${response.status}`);
    }
  } catch (error) {
    console.error("Error processing document:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};

// Function to get feedback
const getFeedback = async (userResponse, currentQuestion, openAIAnswer) => {
  try {
    if (!currentQuestion || !openAIAnswer) {
      throw new Error("La domanda corrente o la risposta di OpenAI non possono essere vuote.");
    }

    const requestBody = JSON.stringify({ userResponse, currentQuestion, openAIAnswer });

    const response = await axiosInstance.post("/get-feedback", requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

// Function to transcribe audio
const transcribeAudio = async (audioBlob) => {
  try {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.mp3");
    formData.append("model", "whisper-1");

    console.log("Sending request to OpenAI API with formData:", formData);

    const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("API request failed with status:", response.status);
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    console.log("Error transcribing audio:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};

const processImage = async (imageFile) => {
  try {
    // Convert image to base64
    const base64Image = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imageFile);
    });

    const requestBody = {
      model: "gpt-4o",
      messages: [
        {
          role: "user",
          content: [
            {
              type: "text",
              text: "Please extract and return only the text content from this image, without any additional commentary or formatting."
            },
            {
              type: "image_url",
              image_url: {
                url: `data:${imageFile.type};base64,${base64Image}`,
              }
            }
          ],
        }
      ]
    };

    const response = await axios.post('https://api.openai.com/v1/chat/completions', requestBody, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200 && response.data.choices && response.data.choices.length > 0) {
      return response.data.choices[0].message.content;
    } else {
      console.error("API request unexpected response:", response);
      throw new Error(`API request unexpected response with status ${response.status}`);
    }
  } catch (error) {
    console.error("Error processing image:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw new Error(error.response?.data?.error?.message || error.message || "Unknown error occurred");
  }
};

const generateSpeech = async (text) => {
  try {
    const response = await axios.post('https://api.openai.com/v1/audio/speech', {
      model: "tts-1",
      voice: "nova",
      input: text,
    }, {
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
      responseType: 'arraybuffer',
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("API request failed with status:", response.status);
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error("Error generating speech:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};

export { processDocument, getFeedback, transcribeAudio, processImage, generateSpeech };