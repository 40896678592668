import React from 'react';
import PropTypes from 'prop-types';
import './feebackAnswers.scss';

const FeedbackAnswers = ({ feedback }) => {
  return (
    <p className='feedback-answer'>{feedback}</p>
  );
};

FeedbackAnswers.propTypes = {
  feedback: PropTypes.string.isRequired,
};

export default FeedbackAnswers;
