import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@ui/button/button';
import Header from '../header/header';
import { motion } from 'framer-motion'; // Se disponibile nel progetto

import './Chisiamo.scss';

const Chisiamo = () => {
  const navigate = useNavigate();

  const teamMembers = [
    {
      name: "Il Team di StudierAI",
      role: "Sviluppatori & Designer",
      description: "Uniamo tecnologia e innovazione per trasformare il modo di studiare, creando soluzioni intuitive ed efficaci per ogni studente."
    },
    {
      name: "La Nostra Community",
      role: "Studenti",
      description: "Il cuore di StudierAI. Il feedback degli studenti guida il continuo miglioramento della nostra piattaforma."
    },
    {
      name: "I Nostri Esperti",
      role: "Professori",
      description: "Professori qualificati che garantiscono l'eccellenza e l'affidabilità dei nostri contenuti."
    }
  ];

  const milestones = [
    {
      year: "Giugno 2023",
      title: "Nascita di StudierAI",
      description: "StudierAI prende vita con l'obiettivo di supportare gli studenti nelle simulazioni degli esami orali."
    },
    {
      year: "Agosto 2023",
      title: "Sviluppo della Versione 1.0",
      description: "Inizia lo sviluppo della prima versione dell'app, con un focus sulle funzionalità di simulazione."
    },
    {
      year: "Giugno 2024",
      title: "Lancio della Versione 1.5",
      description: "La versione 1.0 viene lanciata ufficialmente, disponibile per tutti gli studenti."
    },
    {
      year: "Settembre 2024",
      title: "Sviluppo della Versione 2.0",
      description: "Aggiornamento alla versione 2.0 con nuove funzionalità e miglioramenti per l'utente."
    },
    {
      year: "Ottobre 2024",
      title: "Lancio Versione 2.0",
      description: "Rilascio della versione 2.0 ufficialmente."
    },
    {
      year: "Coming soon...",
      title: "Versione 3.0",
      description: "Lancio della versione 3.0, che introduce importanti novità e funzionalità avanzate."
    },
    {
      year: "2026",
      title: "Nuove Espansioni",
      description: "StudierAI si espande e approda su nuovi mercati."
    }
  ];
  

  return (
    <div className="page-chisiamo">
      <Header />
      <div className="chisiamo-section">
        {/* Hero Section con Gradient Background */}
        <section className="chisiamo-section__hero">
          <div className="chisiamo-container">
            <motion.h1 
              className="chisiamo-section__title"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Chi Siamo
            </motion.h1>
            <motion.p 
              className="chisiamo-section__subtitle"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              Miglioriamo il modo di studiare attraverso l'intelligenza artificiale
            </motion.p>
          </div>
          <div className="hero-gradient-overlay"></div>
        </section>

        {/* Mission Section con Card Design */}
        <section className="chisiamo-section__mission">
          <div className="chisiamo-container">
            <div className="mission-card">
              <h2>La Nostra Missione</h2>
              <p>
              Rendere la ripetizione più veloce ed efficace attraverso un'esperienza interattiva e personalizzata, accessibile a tutti gli studenti.
              </p>
            </div>
          </div>
        </section>

        {/* Timeline Section con Design Moderno */}
        <section className="chisiamo-section__timeline">
          <div className="chisiamo-container">
            <h2>Il Nostro Percorso</h2>
            <div className="chisiamo-section__timeline-grid">
              {milestones.map((milestone, index) => (
                <motion.div 
                  key={index} 
                  className="chisiamo-section__timeline-item"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                >
                  <div className="milestone-content">
                    <div className="chisiamo-section__timeline-year">{milestone.year}</div>
                    <h3>{milestone.title}</h3>
                    <p>{milestone.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Team Section con Design Moderno */}
        <section className="chisiamo-section__team">
          <div className="chisiamo-container">
            <h2>Il Nostro Team</h2>
            <div className="chisiamo-section__team-grid">
              {teamMembers.map((member, index) => (
                <motion.div 
                  key={index}
                  className="chisiamo-section__team-member"
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="member-content">
                    <h3>{member.name}</h3>
                    <p className="chisiamo-section__team-member-role">{member.role}</p>
                    <p className="chisiamo-section__team-member-desc">{member.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section con Gradient Background */}
        <section className="chisiamo-section__cta">
          <div className="chisiamo-container">
            <h2>Prova gratuitamente StudierAI</h2>
            <p>
              Scopri come StudierAI può aiutarti a preparare i tuoi esami in modo più efficace
            </p>
            <div className="chisiamo-section__cta-buttons">
              <Button 
                variant="primary" 
                size="lg" 
                onClick={() => navigate('/signUp')}
                className="chisiamo-button"
              >
                Scopri StudierAI
              </Button>
            </div>
          </div>
          <div className="cta-gradient-overlay"></div>
        </section>
      </div>
    </div>
  );
};

export default Chisiamo;
