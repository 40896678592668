import React, { useEffect, useState, useCallback } from "react";
import "./landing.scss";

// Component imports
import Header from "./header/header";
import Hero from "./hero/hero";
import Functionality from "./functionality/functionality";
import Price from "./price/price";
import Faq from "./faq/faq";
import Footer from "./footer/footer";
import { BetaTestimonialSlider } from "./betaTestimonialSlider/betaTestimonialSlider";
import VideoSection from "./VideoSection/VideoSection";
import LandingMenu from "../../containers/landingMenu/landingMenu";
import StudierAIStats from "./StudierAIStats/StudierAIStats";

// Constants
const COPILOT_CONFIG = {
  scriptUrl: "https://cdn.chatsimple.ai/ai-loader.js",
  platformId: "300ee36b-5b99-4b77-aa3b-bbb175369bb4",
  userId: "27247301-c3a5-4c2b-bd6d-ad8842a60892",
  chatbotId: "93628680-8baf-4c76-be11-567c44466fb4"
};

const Landing = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Memoize callback for better performance
  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  // CoPilot setup
  useEffect(() => {
    const addCoPilot = () => {
      if (!document.querySelector(`script[src="${COPILOT_CONFIG.scriptUrl}"]`)) {
        const script = document.createElement('script');
        script.src = COPILOT_CONFIG.scriptUrl;
        script.defer = true;
        document.body.appendChild(script);
      }

      if (!document.querySelector('co-pilot')) {
        const copilot = document.createElement('co-pilot');
        copilot.setAttribute('platform-id', COPILOT_CONFIG.platformId);
        copilot.setAttribute('user-id', COPILOT_CONFIG.userId);
        copilot.setAttribute('chatbot-id', COPILOT_CONFIG.chatbotId);
        copilot.setAttribute('is-local', 'false');
        
        const link = document.createElement('a');
        link.href = 'https://www.chatsimple.ai/?utm_source=widget&utm_medium=referral';
        link.textContent = 'AI Nav';
        copilot.appendChild(link);
        
        document.body.appendChild(copilot);
      }
    };

    addCoPilot();
    
    // Cleanup
    return () => {
      const copilot = document.querySelector('co-pilot');
      copilot?.remove();
    };
  }, []);

  // CoPilot visibility
  useEffect(() => {
    const copilot = document.querySelector('co-pilot');
    if (copilot) {
      copilot.classList.toggle('hide-copilot', isMenuOpen);
    }
  }, [isMenuOpen]);

  // Smooth scroll handling
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        element?.scrollIntoView({ behavior: "smooth" });
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Initial check
    
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  const MainContent = () => (
    <>
      <Header />
      <main>
        <Hero />
        <section id="functionality">
          <Functionality />
        </section>
        <section id="video-section">
          <VideoSection />
        </section>
        <section id="beta-testimonial-slider">
          <BetaTestimonialSlider />
        </section>
        <section id="price">
          <Price />
        </section>
        <section id="studierai-stats">
          <StudierAIStats />
        </section>
        <section id="faq">
          <Faq />
        </section>
      </main>
      <Footer />
      {isMenuOpen && (
        <div 
          className="menu-overlay" 
          role="dialog" 
          aria-modal="true"
        >
          <LandingMenu onClose={handleMenuClose} />
        </div>
      )}
    </>
  );

  return (
    <div className="Landing">
      <MainContent />
    </div>
  );
};

export default Landing;
