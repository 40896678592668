import React from "react";
import "./documentsQALoader.scss";

import Spinner from "@ui/spinner/spinner";
import { useSelector } from "react-redux";

const DocumentsQALoader = () => {

  const { isGenerate } = useSelector(state => state.document)
  
  return (
    <div data-testid="documents-qa-loader" className="DocumentsQALoader">
      <Spinner size="md" />
      <p>{isGenerate ? 'Caricamento file in corso' : 'Estrazione domande e risposte dai documenti caricati'}</p>
    </div>
  );
};

export default DocumentsQALoader;
