import React, { useRef } from "react";
import "./chatModalFreeSubscription.scss";
import { ModalRoot } from "@ui/modal/modal";
import Button from "@ui/button/button";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ChatModalFreeSubscription = ({ onClose, examCount, uploadingDoc }) => {
  const navigate = useNavigate();
  const dialogRef = useRef(null);
  const { user } = useSelector(state => state.auth);

  //Function GA4 event trigger 
  const handleTriggerEvent = () => {
    let event = uploadingDoc ? 'uploadDocToPremium' : 'ExamToPremium';  
    
      if (typeof window.gtag === 'function') {
        window.gtag('event', event, {
          'event_category': 'Account',
          'event_label': event,
          'email': user.email,
        });        
      } else {
        console.error("gtag is not defined");
      }

    navigate("/home/piani");
  };

  return (
    <ModalRoot.Modal ref={dialogRef} className="ChatModalFreeSubscription">
      <ModalRoot.Header
        className="ChatModalFreeSubscription__header"
        title="Abbonati ora per continuare"
        onClose={onClose}
      />
      <ModalRoot.Content className="ChatModalFreeSubscription__content">
        <p>
          { uploadingDoc 
            ? 'Puoi caricare massimo 2 documenti componibili per il tuo piano gratuito.'
            
            : `Hai completato ${examCount} esame. Per continuare ad usare StudierAI, abbonati al piano premium.`
          }
        </p>
        <Button
          className="ChatModalFreeSubscription__button"
          size="md"
          variant="primary"
          onClick={handleTriggerEvent}
        >
          Aggiorna piano
        </Button>
      </ModalRoot.Content>
    </ModalRoot.Modal>
  );
};

// chat modal free subscription props types
ChatModalFreeSubscription.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChatModalFreeSubscription;