import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Heading from "@ui/heading/heading";
import PriceCard from "@containers/priceCard/priceCard";


import "./price.scss";

const Price = () => {
  const navigate = useNavigate();

  return (
    <div id="price" className="Price">
      <div className="container">
        <div className="justify-center row">
          <div className="col-xs-12 col-lg-8">
            <Heading title="Piani e Prezzi" heading="h2" />
            <p className="Price__text">
              Scegli il piano che meglio si adatta alle tue esigenze e inizia a migliorare il tuo metodo di studio oggi stesso.
            </p>
          </div>
          <div className="col-xs-12">
            <div className="items-center justify-center Price__card-row row g-5 gx-sm-5">
              <div className="col-xs-12 col-sm-6 col-lg-4">
                <PriceCard
                  title="Prova Gratuita"
                  cost="0"
                  method="al mese"
                  options={["Caricamento fino a 2 documenti", "Ripetizione documento", "Flashcards automatiche", "Assistenza"]}
                  buttonText="Iscriviti Gratis"
                  onClick={() => navigate("/signUp")}
                  freeNote="Nessuna carta di credito richiesta per la registrazione"
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-4">
                <PriceCard
                  title="Premium"
                  originalCost="14.99"
                  cost="4.99"
                  method="al mese"
                  options={[
                    "Caricamento documenti illimitati",
                    "Ripetizione documento illimitata",
                    "Simulazione interrogazioni orali illimitata",
                    "Voti personalizzati",
                    "Flashcards illimitate",
                    "Accesso a tutte le funzionalità future in anteprima",
                    "Assistenza Prioritaria",
                  ]}
                  buttonText="Acquista Ora"
                  onClick={() => navigate("/signUp")}
                  advised
                  popularTag="Offerta a tempo"
                  showCountdown={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Definizione dei prop types
Price.propTypes = {
  navigate: PropTypes.func,
};

export default Price;
