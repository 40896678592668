import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './feedback.scss';

const Feedback = ({
  feedback,
  onFeedback,
  isDisabled = false,
  questionId
}) => {
  const clickTimeoutRef = useRef(null);
  const isProcessingRef = useRef(false);

  const handleFeedbackClick = useCallback((newValue) => {
    // Previeni click multipli
    if (isProcessingRef.current) return;
    
    // Se il feedback è già uguale al nuovo valore, non fare nulla
    if (feedback?.isHelpful === newValue) return;

    isProcessingRef.current = true;

    // Pulisci eventuali timeout pendenti
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    onFeedback(newValue);

    // Resetta il flag di processing dopo un breve delay
    clickTimeoutRef.current = setTimeout(() => {
      isProcessingRef.current = false;
    }, 300);
  }, [feedback, onFeedback]);

  return (
    <div className="Feedback">
      <button
        className={clsx(
          "Feedback__button",
          feedback?.isHelpful === true && "Feedback__button--active Feedback__button--thumbs-up",
        )}
        onClick={() => handleFeedbackClick(true)}
        disabled={isDisabled || isProcessingRef.current}
        aria-label="Feedback positivo"
        data-question-id={questionId}
      >
        <div className="Feedback__icon Feedback__icon--thumbs-up" />
      </button>
      <button
        className={clsx(
          "Feedback__button",
          feedback?.isHelpful === false && "Feedback__button--active Feedback__button--thumbs-down",
        )}
        onClick={() => handleFeedbackClick(false)}
        disabled={isDisabled || isProcessingRef.current}
        aria-label="Feedback negativo"
        data-question-id={questionId}
      >
        <div className="Feedback__icon Feedback__icon--thumbs-down" />
      </button>
    </div>
  );
};

Feedback.propTypes = {
  feedback: PropTypes.shape({
    isHelpful: PropTypes.bool,
    timestamp: PropTypes.number,
    userId: PropTypes.string
  }),
  onFeedback: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  questionId: PropTypes.string
};

export default React.memo(Feedback);