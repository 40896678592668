import React, { useState } from "react";
import "./accordionItem.scss";

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`AccordionItem ${isOpen ? 'is-open' : ''}`}>
      <button className="AccordionItem__question" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className="AccordionItem__icon">{isOpen ? '▼' : '▶'}</span>
      </button>
      {isOpen && <p className="AccordionItem__answer">{answer}</p>}
    </div>
  );
};

export default AccordionItem;