import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";

const useAudio = () => {
  const [showAudio, setShowAudio] = useState(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState(() => {
    return localStorage.getItem("isPermissionGranted") === "true";
  });

  const requestAudioPermissions = useCallback(async () => {
    if (isPermissionGranted) {
      return;
    }
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsPermissionGranted(true);
      localStorage.setItem("isPermissionGranted", "true");
      toast.success("Permessi microfono abilitati.");
    } catch (error) {
      console.error("Microphone permissions denied:", error);
      toast.error("Permessi microfono disabilitati. Per favore abilita l'accesso al microfono nelle impostazioni del tuo browser.");
      setIsPermissionGranted(false);
    }
  }, [isPermissionGranted]);

  const showAudioRecorder = useCallback(() => {
    if (isPermissionGranted) {
      setShowAudio(true);
    }
  }, [isPermissionGranted]);

  const hideAudioRecorder = useCallback(() => setShowAudio(false), []);

  // Request permissions on page load
  useEffect(() => {
    requestAudioPermissions();
  }, [requestAudioPermissions]);

  return { showAudio, showAudioRecorder, hideAudioRecorder, isPermissionGranted };
};

export default useAudio;
