import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../../../redux/firebase';
import './Testimonials.scss';

const Testimonials = () => {
  const [userCount, setUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStudentsCount = async () => {
      try {
        setIsLoading(true);
        const studentsRef = collection(firestore, 'students');
        const snapshot = await getDocs(studentsRef);

        const validStudentsCount = snapshot.docs.filter(doc => {
          const data = doc.data();
          return data && data.email;
        }).length;

        setUserCount(validStudentsCount);
        setIsLoading(false);
        console.log('Numero totale di studenti:', validStudentsCount);
      } catch (error) {
        console.error('Errore nel conteggio degli studenti:', error);
        setIsLoading(false);
      }
    };

    fetchStudentsCount();

    const intervalId = setInterval(fetchStudentsCount, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const stars = Array(5).fill().map((_, i) => (
    <span key={i} className="star">★</span>
  ));

  return (
    <div className="Testimonials">
      <div className="Testimonials__stars">
        {stars}
      </div>
      <div className="Testimonials__text">
        {isLoading ? (
          <div className="Testimonials__loading">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        ) : (
          <>
            <strong>+{userCount.toLocaleString('it-IT')}</strong> Studenti si preparano al meglio l'orale!
          </>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
