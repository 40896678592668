import React from "react";
import usePayment from "./hook/usePayment";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Heading from "@ui/heading/heading";
import PriceCard from "@containers/priceCard/priceCard";
import Loader from "./loader/loader"; 

import "./index.scss";

const Plans = () => {
  const { isLoading, upgradePlan, manageSubscription } = usePayment();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);

  const isPremium = user.plan === 'Premium';

  return (
    <div className="Plans">
      <div className="container">
        <div className="justify-center row">
          <div className="Plans__info col-xs-12 col-md-10 col-lg-6">
            <Heading heading="h4" title="Piani e Prezzi" />
            <p>Sei a un passo dal dire addio alle scene mute!</p>
          </div>
          <div className="col-xs-12">
            {isLoading && <Loader />}
            {!isLoading && (
              <div className="justify-center Plans__cards just row">
                {pathname !== "/home/piani" && (
                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <PriceCard
                      title="Prova Gratuita"
                      cost="0"
                      method="mese"
                      options={["Caricamento max 2 documenti", "Ripetezione documento limitata a 5 domande"]}
                      buttonText="Iscriviti"
                    />
                  </div>
                )}
                <div className="col-xs-12 col-sm-10 col-lg-4">
                  <PriceCard
                    isDisabled={isLoading}
                    title="Premium"
                    originalCost={isPremium ? null : "14.99"}
                    cost="4.99"
                    method="mese"
                    options={[
                      "Caricamento documenti illimitati",
                      "Ripetizione documento illimitata",
                      "Simulazione interrogazioni orali illimitata",
                      "Voti personalizzati",
                      "Flashcards illimitate",
                      "Accesso a tutte le funzionalità future in anteprima",
                      "Assistenza Prioritaria",
                    ]}
                    buttonText={isPremium ? "Gestisci abbonamento" : "Acquista"}
                    onClick={isPremium ? manageSubscription : () => upgradePlan("price_1PG0D4HFdskyUb7jacTWL7Yq")}
                    popularTag={!isPremium ? "Offerta a tempo" : null}
                    showCountdown={!isPremium}
                    advised={!isPremium}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
