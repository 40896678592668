import React, { useEffect, useState } from "react";
import "./countdown.scss";

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({ 
    days: '0', 
    hours: '0', 
    minutes: '0', 
    seconds: '0' 
  });

  useEffect(() => {
    const targetDate = new Date('2024-11-10T23:59:59');

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setTimeLeft({ 
          days: '0', 
          hours: '0', 
          minutes: '0', 
          seconds: '0' 
        });
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({
        days: String(days).padStart(2, '0'),
        hours: String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0')
      });
    };

    calculateTimeLeft();

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);

  if (timeLeft.days === '0' && timeLeft.hours === '0' && 
      timeLeft.minutes === '0' && timeLeft.seconds === '0') {
    return null;
  }

  return (
    <div className="Countdown-container">
      <div>
        <div className="Countdown-container__card">
          <span className="Countdown-container__time">{timeLeft.days}</span>
        </div>
        <div className="Countdown-container__label">Giorni</div>
      </div>

      <div>
        <div className="Countdown-container__card">
          <div className="Countdown-container__time">{timeLeft.hours}</div>
        </div>
        <div className="Countdown-container__label">Ore</div>
      </div>

      <div>
        <div className="Countdown-container__card">
          <div className="Countdown-container__time">{timeLeft.minutes}</div>
        </div>
        <div className="Countdown-container__label">Minuti</div>
      </div>

      <div>
        <div className="Countdown-container__card">
          <div className="Countdown-container__time">{timeLeft.seconds}</div>
        </div>
        <div className="Countdown-container__label">Secondi</div>
      </div>
    </div>
  );
};

export default Countdown;

