import { useState } from 'react'
import { useDispatch } from 'react-redux'


const useDropFiles = uploadNotes => {
  const [isDragging, setIsDragging] = useState(false)
  const dispatch = useDispatch();


  // set dragging state
  const onDragOver = e => {
    e.preventDefault()
    setIsDragging(true)
  }


  // reset dragging state
  const onDragLeave = e => {
    e.preventDefault()
    setIsDragging(false)
  }


  // upload notes on drop
  const dropFiles = e => {
    e.preventDefault()
    setIsDragging(false)
    dispatch({ type: "SET_GENERATE", payload: true });


    // check if files are empty
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return


    // clear data transfer and upload notes
    const files = e.dataTransfer.files
    e.dataTransfer.clearData()
    uploadNotes(files)
  }


  return { isDragging, onDragOver, onDragLeave, dropFiles }
}


export default useDropFiles
