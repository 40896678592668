import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import useLazyLoadingImage from "@hooks/useLazyLoadingImage";

import Heading from "@ui/heading/heading";

import "./functionalityItem.scss";

const FunctionalityItem = ({ title, text, image }) => {
  const { loaded, onLoad } = useLazyLoadingImage();

  return (
    <div className="FunctionalityItem">
      <div className="FunctionalityItem__content">
        <div className="FunctionalityItem__text">
          <Heading title={title} heading="h2" className="FunctionalityItem__title" />
          <p className="FunctionalityItem__description">{text}</p>
        </div>
      </div>
      <div className="FunctionalityItem__image-container">
        <img
          className={clsx("FunctionalityItem__image", !loaded && "blurImage")}
          src={image}
          alt={title}
          onLoad={onLoad}
        />
      </div>
    </div>
  );
};

FunctionalityItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default FunctionalityItem;