import {
  SET_DOCUMENT,
  SET_EXTRACTED_QA,
  SET_DOCUMENT_TITLE,
  SET_SUBMITTING_DOC,
} from './types';
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  writeBatch,
  increment,
} from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import { getTypeFile } from '@lib/file';

const BATCH_SIZE = 500;

// Azioni sincrone
export const setExtractedQA = (documentId, extractedQA) => ({
  type: SET_EXTRACTED_QA,
  payload: { documentId, extractedQA },
});

export const setDocumentTitle = (documentId, newTitle) => ({
  type: SET_DOCUMENT_TITLE,
  payload: { documentId, newTitle },
});

export const setSubmitDocState = (submitState) => ({
  type: SET_SUBMITTING_DOC,
  payload: submitState,
});

// Utility per controllo feedback valido
const isValidFeedback = (feedback) => {
  return feedback && typeof feedback.isHelpful === 'boolean';
};

// Salva domande e feedback in batch
const saveQABatch = async (questions, feedback = [], documentId, userEmail) => {
  try {
    const batches = [];
    let currentBatch = writeBatch(firestore);
    let operationsCount = 0;

    const validFeedbackCount = feedback.filter(isValidFeedback).length;
    console.log(
      `💾 Preparazione salvataggio: ${questions.length} domande, ${validFeedbackCount} feedback`
    );

    for (let i = 0; i < questions.length; i++) {
      if (operationsCount >= BATCH_SIZE) {
        batches.push(currentBatch);
        currentBatch = writeBatch(firestore);
        operationsCount = 0;
      }

      const qa = questions[i];
      const currentFeedback = feedback[i];
      const questionRef = doc(collection(firestore, 'questions'));

      const questionData = {
        ...qa,
        documentID: documentId,
        flaggedWrong: false,
        uploadDate: serverTimestamp(),
        lastModified: serverTimestamp(),
      };

      // Controlla che il feedback sia valido prima di aggiungerlo
      if (isValidFeedback(currentFeedback)) {
        questionData.feedback = {
          isHelpful: currentFeedback.isHelpful,
          timestamp: serverTimestamp(),
          userId: auth.currentUser.uid,
          createdAt: currentFeedback.timestamp || serverTimestamp(),
        };
      }

      currentBatch.set(questionRef, questionData);
      operationsCount++;

      // Aggiorna statistiche utente solo se c'è un feedback valido
      if (isValidFeedback(currentFeedback)) {
        const userStatsRef = doc(firestore, 'userStats', auth.currentUser.uid);
        currentBatch.set(
          userStatsRef,
          {
            email: userEmail,
            totalFeedbackGiven: increment(1),
            totalDocumentsWithFeedback: increment(1),
            [`feedback${currentFeedback.isHelpful ? 'Positive' : 'Negative'}`]:
              increment(1),
            lastFeedbackDate: serverTimestamp(),
          },
          { merge: true }
        );
        operationsCount++;
      }
    }

    if (operationsCount > 0) {
      batches.push(currentBatch);
    }

    // Esegui tutti i batch in sequenza
    console.log(`📦 Esecuzione ${batches.length} batch di salvataggio`);

    for (let i = 0; i < batches.length; i++) {
      try {
        await batches[i].commit();
        const processedQuestions = Math.min(
          (i + 1) * BATCH_SIZE,
          questions.length
        );
        console.log(
          `✅ Batch ${i + 1}/${batches.length} completato (${processedQuestions}/${questions.length} domande)`
        );
      } catch (error) {
        console.error(`❌ Errore nel batch ${i + 1}/${batches.length}:`, error);
        throw error;
      }
    }

    return true;
  } catch (error) {
    console.error('❌ Errore nel salvataggio batch:', error);
    throw error;
  }
};

// Azione principale per salvare i documenti
export const setDocument = (documents, feedbackStates = []) => {
  return async (dispatch) => {
    try {
      console.log('🚀 Inizio salvataggio documenti');

      // Controlla se l'utente è autenticato
      const user = auth.currentUser;
      if (!user) {
        throw new Error(
          'Utente non autenticato. Effettua il login per continuare.'
        );
      }
      const userId = user.uid;
      const userEmail = user.email;

      const documentsArray = [];
      const validFeedbacks = feedbackStates.filter(isValidFeedback);

      console.log(
        `📊 Stato iniziale: ${documents.length} documenti, ${validFeedbacks.length} feedback validi`
      );

      for (const document of documents) {
        if (!document.extractedQA?.length) {
          throw new Error(
            'Non ci sono domande e risposte estratte da salvare'
          );
        }

        // Salva il documento principale
        const documentData = {
          title: document.title,
          text: document.text,
          type: getTypeFile(document.type),
          uploadDate: serverTimestamp(),
          uploadedBy: userId,
          userEmail: userEmail, // Aggiunta dell'email dell'utente
          questionsCount: document.extractedQA.length,
          feedbackCount: validFeedbacks.length,
          lastModified: serverTimestamp(),
        };

        const documentRef = await addDoc(
          collection(firestore, 'documents'),
          documentData
        );

        if (!documentRef.id) {
          throw new Error('Errore nel salvataggio del documento principale');
        }

        // Salva domande e feedback
        await saveQABatch(
          document.extractedQA,
          feedbackStates,
          documentRef.id,
          userEmail
        );

        // Aggiorna Redux
        dispatch(setExtractedQA(documentRef.id, document.extractedQA));
        documentsArray.push({ id: documentRef.id, ...documentData });

        console.log(`📄 Documento "${document.title}" salvato con successo`);
      }

      // Aggiorna lo store Redux
      dispatch({ type: SET_DOCUMENT, payload: documentsArray });
      console.log('✅ Salvataggio completato con successo');

      // L'aggiornamento di 'totalDocumentsWithFeedback' è stato spostato in 'userStats'
      // Non è più necessario aggiornare la collezione 'users'

    } catch (error) {
      console.error('❌ Errore nel salvataggio:', error);
      throw error;
    }
  };
};

export const changeDocumentTitle = (documentId, newTitle) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          title: newTitle,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentTitle(documentId, newTitle));
      console.log('✅ Titolo documento modificato con successo');
    } catch (error) {
      console.error('❌ Errore modifica titolo:', error);
      throw error;
    }
  };
};
