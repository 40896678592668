export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_DOCUMENT_TITLE = 'SET_DOCUMENT_TITLE';
export const SET_EXTRACTED_QA = 'SET_EXTRACTED_QA';
export const SET_SUBMITTING_DOC = 'SET_SUBMITTING_DOC';
export const LOGIN_USER = 'LOGIN_USER';
export const SET_PROCESSING_STATE = "SET_PROCESSING_STATE";
export const SET_GENERATE = "SET_GENERATE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_DOCUMENTS_QA = "SET_DOCUMENTS_QA";
