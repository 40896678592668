import { 
  SET_DOCUMENT,
  SET_EXTRACTED_QA,
  SET_SUBMITTING_DOC, 
  SET_PROCESSING_STATE, 
  SET_GENERATE, 
  SET_IS_LOADING,
  SET_DOCUMENTS_QA
} from '../actions/types';

const initialState = {
  documents: [],
  extractedQA: [],
  isSubmitting: false,
  isProcessing: false,
  isGenerate: false,
  isLoading: false,
  documentsQA: [],
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        documents: [...action.payload],
      };
    case SET_EXTRACTED_QA:
      return {
        ...state,
        extractedQA: action.payload,
      };
    case SET_DOCUMENTS_QA:
      return {
        ...state,
        documentsQA: action.payload,
      };
    case SET_PROCESSING_STATE:
      return {
        ...state,
        isProcessing: action.payload,
      };
    case SET_GENERATE:
      return {
        ...state,
        isGenerate: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_SUBMITTING_DOC:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    default:
      return state;
  }
};

export default documentReducer;
