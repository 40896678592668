import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@ui/button/button';
import { TypewriterEffect } from './TypewriterEffect';
import Testimonials from './Testimonials/Testimonials';
import Banner from './Banner/Banner'; // Importa il nuovo componente

import './hero.scss';

const Hero = () => {
  const navigate = useNavigate();

  const words = [
    { text: "Simula l'interrogazione " },
    { text: "Simula l'esame orale" }
  ];

  return (
    <div className="Hero">
      <div className="container">
        <Banner /> 
        <div className="Hero__content">
          <TypewriterEffect
            words={words}
            className="Hero__typewriter"
            cursorClassName="Hero__cursor"
            typingSpeed={0.03}
          />
          <p className="Hero__text">
            Trasforma i tuoi documenti di studio in simulazioni orali. Ricevi feedback immediati e voti realistici su ogni interrogazione.
          </p>
          <Button className="Hero__btn" size="lg" variant="primary" onClick={() => navigate("/signUp")}>
            Prova ora Gratis
          </Button>
          <Button
            className="Hero__btn Hero__btn--login"
            size="md" variant="tertiary"
            onClick={() => navigate("/signIn")}
          >
            Accedi
          </Button>
          <Testimonials />
        </div>
      </div>
    </div>
  );
};

export default Hero;
