import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./betaTestimonialSlider.scss";
import BetaTestimonialSliderItem from "./BetaTestimonialSliderItem/BetaTestimonialSliderItem";

const betaReviews = [
  {
    name: "Marco, 20",
    text: "All'inizio ero scettico, ma StudierAI mi ha sorpreso. Le flashcard interattive mi hanno aiutato a memorizzare concetti complessi di biologia in modo più efficace.",
    stars: 4.5,
  },
  {
    name: "Giulia, 19",
    text: "Le simulazioni delle interrogazioni orali di StudierAI sono davvero d'aiuto. Non sono perfette, ma mi hanno aiutato a sentirmi più sicura durante gli esami.",
    stars: 4.5,
  },
  {
    name: "Andrea, 22",
    text: "Prima faticavo a organizzare le risposte durante gli orali di Storia delle Dottrine. Con StudierAI ho imparato a strutturare meglio il discorso. Risultato? Da 18 a 24!",
    stars: 5,
  },
  {
    name: "Sofia, 16",
    text: "Terzo anno di liceo, super in ansia per le interrogazioni di filosofia. Le simulazioni mi hanno aiutato a gestire meglio l'ansia. Da 6 stentato a 7.5 ",
    stars: 4.5,
  },
  {
    name: "Lorenzo, 18",
    text: "Uso StudierAI spesso e devo dire che ha reso lo studio più interessante. Le simulazioni sono il mio strumento preferito. L'unico neo? Vorrei più contenuti per materie scientifiche.",
    stars: 4.5,
  },
  {
    name: "Vittoria, 22",
    text: "Come studentessa di legge, StudierAI è utilissimo per preparare gli esami. Le simulazioni mi aiutano a ripassare gli argomenti più complessi. Non vedo l'ora delle prossime funzionalità!",
    stars: 5,
  },
  {
    name: "Luca, 21",
    text: "Come studente di ingegneria, le simulazioni di StudierAI mi aiutano a preparare meglio gli orali. Ho imparato a spiegare concetti complessi in modo più chiaro. Vorrei più contenuti specifici per materie tecniche.",
    stars: 4.5,
  },
  {
    name: "Chiara, 16",
    text: "StudierAI ha migliorato il mio modo di preparare gli esami di lingue. La funzione di simulazione delle conversazioni è incredibilmente utile per migliorare la fluenza. Non vedo l'ora di vedere come si evolverà!",
    stars: 5,
  },
  {
    name: "Alessandro, 23",
    text: "Come studente lavoratore, il tempo è prezioso. StudierAI mi aiuta a ottimizzare le sessioni di studio con simulazioni mirate. Perfetto per prepararsi in modo efficiente!",
    stars: 4.5,
  },
  {
    name: "Elena, 17",
    text: "Ero scettica all'inizio, ma le simulazioni mi hanno davvero aiutato con le interrogazioni di storia. Da quando mi esercito con StudierAI sono passata da 6- a 7.5. La differenza si vede! ",
    stars: 5,
  }
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const BetaTestimonialSlider = () => {
  return (
    <div id="beta-testimonial-slider" className="BetaTestimonialSlider">
      <div className="BetaTestimonialSlider__container">
        <h2 className="BetaTestimonialSlider__title">Recensioni</h2>
        <Slider {...settings}>
          {betaReviews.map((review, index) => (
            <BetaTestimonialSliderItem key={index} review={review} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

BetaTestimonialSlider.propTypes = {
  betaReviews: PropTypes.arrayOf(
    PropTypes.shape({
      stars: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default BetaTestimonialSlider;